import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';

function BgImage({ isZoomed = false, onLoaded }) {
  const [bgLoaded, setBgLoaded] = useState(false);
  const [shelfLoaded, setShelfLoaded] = useState(false);

  useEffect(() => {
    if (shelfLoaded && bgLoaded) {
      onLoaded();
    }
  }, [shelfLoaded, bgLoaded]);

  const getShelfImageClasses = () =>
    isZoomed
      ? `${styles['bar-shelf']} `
      : `${styles['bar-shelf']} ${styles['bar-shelf--hidden']} `;

  const getBgImageClasses = () =>
    isZoomed
      ? `${styles['bg-image']} ${styles['bg-image--zoomed']}`
      : `${styles['bg-image']}`;

  return (
    <>
      <img
        alt="bg-logo"
        className={getBgImageClasses()}
        src="/sv-gaming/bacardi/assets/images-bacardi/bg.png"
        onLoad={() => setBgLoaded(true)}
      />

      <img
        alt="bg-shelf"
        className={getShelfImageClasses()}
        src="/sv-gaming/bacardi/assets/images-bacardi/bar-shelf.png"
        onLoad={() => setShelfLoaded(true)}
      />
    </>
  );
}

export default BgImage;
