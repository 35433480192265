const imgs = [
  '/sv-gaming/bacardi/assets/images-bacardi/btn-container.png',
  '/sv-gaming/bacardi/assets/images-bacardi/logo-image.png',
  '/sv-gaming/bacardi/assets/images-bacardi/bg.png',
  '/sv-gaming/bacardi/assets/images-bacardi/bar-shelf.png',
  '/sv-gaming/bacardi/assets/images-bacardi/bacardi-rum.png',
  '/sv-gaming/bacardi/assets/images-bacardi/pine-juice.png',
  '/sv-gaming/bacardi/assets/images-bacardi/lemon-juice.png',
  '/sv-gaming/bacardi/assets/images-bacardi/orange-juice.png',
  '/sv-gaming/bacardi/assets/images-bacardi/soda.png',
  '/sv-gaming/bacardi/assets/images-bacardi/cola.png',
  '/sv-gaming/bacardi/assets/images/glass-tall.png',
  '/sv-gaming/bacardi/assets/images-bacardi/bacadi-recipe.png',
];

const cacheImages = async () => {
  try {
    const promises = await imgs.map((src) => {
      return new Promise((resolve, reject) => {
        // eslint-disable-next-line no-undef
        const img = new Image();
        img.src = src;

        img.onload = resolve;
        img.onerror = reject;
      });
    });
    await Promise.all(promises);
    return true;
  } catch (error) {
    // eslint-disable-next-line no-alert
    alert('failed to load assets', error);
  }
};

export default cacheImages;
