import React from 'react';
import { REDIRECT_URL } from '../../constants';
import StartScreenButton from '../StartScreenButton/StartScreenButton';
import styles from './styles.module.css';

function FirstScreenControls({ onStartClicked, onRecipesClicked }) {
  const onBackToMainSite = () => {
    // window.location.href = REDIRECT_URL;
    window.close();
  };

  return (
    <>
      {/* exit button */}
      <div onClick={onBackToMainSite} className={styles.backBtn}>
        <div className={styles['inner-back-btn']}>
          <p className={styles.backBtnText}>EXIT</p>
        </div>
      </div>

      <StartScreenButton
        onClick={onStartClicked}
        isLower={false}
        text="START"
      />
      <StartScreenButton onClick={onRecipesClicked} isLower text="RECIPES" />
      <div className={styles['valve-container']}>
        <img
          alt="valve"
          className={styles.valve}
          src="/sv-gaming/dewars/home-left-boxes.png"
        />
      </div>
    </>
  );
}

export default FirstScreenControls;
