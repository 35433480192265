import React from 'react';
import styles from './styles.module.css';

function BackButton({ onClick }) {
  return (
    <div className={styles.backBtn}>
      <div onClick={onClick} className={styles['inner-back-btn']}>
        <p className={styles.backBtnText}>BACK</p>
      </div>
    </div>
  );
}

export default BackButton;
