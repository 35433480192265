import React, { useState } from 'react';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';
import styles from './styles.module.css';

export default function ImageSliderScreen({
  onBackClick = () => console.log('back clicked'),
  onImageSelected = (imgIndex) => console.log(`selected image ${imgIndex}`),
  initialIndex = 0,
}) {
  const [currentIndex, setCurrentIndex] = useState(initialIndex);

  return (
    <div className={styles.container}>
      <div className={styles.backButton} onClick={onBackClick}>
        BACK
      </div>

      <Carousel
        value={currentIndex}
        onChange={(newIndex) => setCurrentIndex(newIndex)}
        arrows
        arrowLeft={
          <div className={styles.arrow} style={{ height: window.innerHeight }}>
            <RiArrowLeftSLine size={45} color="black" />
          </div>
        }
        arrowRight={
          <div className={styles.arrow} style={{ height: window.innerHeight }}>
            <RiArrowRightSLine size={45} color="black" />
          </div>
        }
        addArrowClickHandler
      >
        <img
          onClick={() => onImageSelected(0)}
          className={styles.images}
          src="/sv-gaming/colorfy/svgs/1.svg"
          alt="img1"
        />
        <img
          onClick={() => onImageSelected(1)}
          className={styles.images}
          src="/sv-gaming/colorfy/svgs/2.svg"
          alt="img2"
        />
        <img
          onClick={() => onImageSelected(2)}
          className={styles.images}
          src="/sv-gaming/colorfy/svgs/3.svg"
          alt="img3"
        />
        <img
          onClick={() => onImageSelected(3)}
          className={styles.images}
          src="/sv-gaming/colorfy/svgs/4.svg"
          alt="img3"
        />
        <img
          onClick={() => onImageSelected(4)}
          className={styles.images}
          src="/sv-gaming/colorfy/svgs/5.svg"
          alt="img3"
        />
        <img
          onClick={() => onImageSelected(5)}
          className={styles.images}
          src="/sv-gaming/colorfy/svgs/6.svg"
          alt="img3"
        />
      </Carousel>
    </div>
  );
}
