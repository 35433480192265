const imgs = [
  '/sv-gaming/dewars/dewars-backdrop.png',
  '/sv-gaming/dewars/dw-12.png',
  '/sv-gaming/dewars/dw-15.png',
  '/sv-gaming/dewars/glass-tall.png',
  '/sv-gaming/dewars/soda.png',
  '/sv-gaming/dewars/ale.png',
  '/sv-gaming/dewars/coconut.png',
  '/sv-gaming/dewars/recipe-btn.png',
  '/sv-gaming/dewars/recipes.png',
  '/sv-gaming/dewars/lemon-bowl.png',
  '/sv-gaming/dewars/leaf-bowl.png',
  '/sv-gaming/dewars/valve.png',
  '/sv-gaming/dewars/home-left-boxes.png',
  '/sv-gaming/dewars/lemon-twist.png',
  '/sv-gaming/dewars/lemon-wheel.png',
];

const cacheImages = async () => {
  try {
    const promises = await imgs.map((src) => {
      return new Promise((resolve, reject) => {
        // eslint-disable-next-line no-undef
        const img = new Image();
        img.src = src;

        img.onload = resolve;
        img.onerror = reject;
      });
    });
    await Promise.all(promises);
    return true;
  } catch (error) {
    // eslint-disable-next-line no-alert
    alert('failed to load assets', error);
  }
};

export default cacheImages;
