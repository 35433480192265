import React from 'react';
import styles from './styles.module.css';

function Full({ color = 'white' }) {
  return (
    <svg
      className={styles['level-svg']}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 34.133 87.033"
    >
      <path
        fill={color}
        d="M-1837.667,351.467h34.133v79.609s-7.733,7.425-15.467,7.425-16.8-7.425-16.8-7.425Z"
        transform="translate(1837.667 -351.467)"
      />
    </svg>
  );
}

export default Full;
