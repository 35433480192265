import React from 'react';
import styles from './styles.module.css';

function Lemon({ enabled, onClick }) {
  return (
    <img
      onClick={() => enabled && onClick()}
      src="/sv-gaming/dewars/lemon-bowl.png"
      alt="lemon"
      className={`${styles.lemon} ${enabled ? '' : styles['lemon--faded']}`}
    />
  );
}

export default Lemon;
