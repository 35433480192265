import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';

function ScoreBoard() {
  const [points, setPoints] = useState(
    localStorage.getItem('bar_game_total_point') || '0',
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setPoints(localStorage.getItem('bar_game_total_point') || '0');
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return <div className={styles.container}>YOUR TOTAL POINTS - {points}</div>;
}

export default ScoreBoard;
