import React from 'react';
import CreateGrid from './CreateGrid.js';

import VideoArr from './VidData.js';
import './assets/styles/VideoPage.css'
import sideImg from './assets/images/over_the_years_img.png';
const VideoCard = props => {
	React.useEffect(()=>{
		let root = document.getElementById('root');
		let f = document.getElementById("footer");
		f.style.backgroundColor="#fe87a0";
		document.body.style.backgroundColor = '#fe87a0';
		if(window.innerWidth<769)
		{
			// setRenderForPhone(true);
			CreateGrid(true, "overTheYearsContent", "firstItem", 1, 1, 3, 0);

		}
		else
		CreateGrid(true, "overTheYearsContent", "firstItem", 1, 6, 5, 0);

	},[])
	return props.videoObj.map((item, index) => {
		return (
			index===0?
			<div key={index} className="vidItem-first" id="firstItem">

				{
				item.videoID.length?
				<div className="vid" id="firstvidPlayer">
					<iframe className="YoutubePlayer" frameBorder="0" src={item.videoID}>
					</iframe>
				</div>
				:
				null
				}
				<div className="cardTitle">
					<h2>{item.title}</h2>
				</div>
				<div className="desc">
					<p>{item.descInfo}</p>
				</div>
			</div>
			:
			<div key={index} className="vidItem">
				{
				item.videoID.length?
				<div className="vid">
					<iframe className="YoutubePlayer" frameBorder="0" src={item.videoID}>
					</iframe>
				</div>
				:
				null
				}
				<div className="cardTitle">
					<h2>{item.title}</h2>
				</div>
				<div className="desc">
					<p>{item.descInfo}</p>
				</div>
			</div>
		)
	})
}

export default props => {
	React.useEffect(()=>{
		props.addLeaves();
		document.getElementById("over-the-year").className="over-the-years-active";
		document.getElementById("over-the-year-link").style.color="white";
		document.getElementById("over-the-year-mob").className="over-the-years-active";
		document.getElementById("over-the-year-link-mob").style.color="white";
		return(()=>{
			document.getElementById("over-the-year").className="";
			document.getElementById("over-the-year-link").style.color="black";
			document.getElementById("over-the-year-mob").className="";
			document.getElementById("over-the-year-link-mob").style.color="black";
			props.removeLeaves()
		})
	},[])
	return (
		<React.Fragment>
			<div className="headingSection">
				<div className="OverTheYearsHeadBlck">
					<h1 className="OverTheYearsHeading">OVER THE YEARS</h1>
				</div>
				<div className="OverTheYearsHeadImg">
					<img className="SideImg" src={sideImg}/>
				</div>

			</div>
			<div>
			</div>

			<div id="overTheYearsContent" className="ContentSection">
				<VideoCard videoObj={VideoArr}/>			
			</div>
		</React.Fragment>
		
	);
};

