import React from 'react';
import styles from './styles.module.css';

const LEVEL_KEYS = {
  CORRECT: 1,
  INCORRECT: 2,
  INCOMPLETE: 3,
};

function StepProgressStatus({
  width = '20px',
  height = '250px',
  levels = 5,
  separatorPercentHeight = 3,
  correctLevelColor = '#21DE24',
  incorrectLevelColor = '#CC1013',
  incompleteLevelColor = '#C5C8C9',
  separatorColor = '#080303',
  currentStatus = [true, true, false],
}) {
  const separators = levels - 1;
  const totalSeparatorsHeight = separators * separatorPercentHeight;
  const singleLevelHeight = (100 - totalSeparatorsHeight) / levels;

  const levelColors = [];

  for (let i = 0; i < levels; i += 1) {
    if (i > currentStatus.length - 1) {
      levelColors.push(LEVEL_KEYS.INCOMPLETE);
    } else {
      // eslint-disable-next-line no-unused-expressions
      currentStatus[i]
        ? levelColors.push(LEVEL_KEYS.CORRECT)
        : levelColors.push(LEVEL_KEYS.INCORRECT);
    }
  }

  const getLevelColor = (levelKey) => {
    switch (levelKey) {
      case LEVEL_KEYS.CORRECT:
        return correctLevelColor;

      case LEVEL_KEYS.INCORRECT:
        return incorrectLevelColor;

      case LEVEL_KEYS.INCOMPLETE:
        return incompleteLevelColor;

      default:
        return incompleteLevelColor;
    }
  };

  return (
    <div className={styles.container} style={{ width, height }}>
      {levelColors.map((levelKey, index) => (
        <>
          <div
            style={{
              backgroundColor: getLevelColor(levelKey),
              height: `${singleLevelHeight}%`,
            }}
          />
          {index < levelColors.length - 1 && (
            <div
              style={{
                backgroundColor: separatorColor,
                height: `${separatorPercentHeight}%`,
              }}
            />
          )}
        </>
      ))}
    </div>
  );
}

export default StepProgressStatus;
