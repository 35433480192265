import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import ColoringScreen from '../ColoringScreen/ColoringScreen';
import ColorSelector from '../ColorSelector/ColorSelector';
import COLOR_CONFIGS from '../GameScreen/colors';
import ImageSliderScreen from '../ImageSliderScreen/ImageSliderScreen';
import SaturationSlider from '../SaturationSlider/SaturationSlider';
import styles from './styles.module.css';
import './fadeTransition.css';
import InitialMessage from '../InitialMessage/InitialMessage';
import { REDIRECT_URL } from '../../constants';

function ColoringAndControls({ onSubmit }) {
  const [currentColor, setCurrentColor] = useState('white');
  const [currentShade, setCurrentShade] = useState('white');

  const [showSlider, setShowSlider] = useState(false);
  const [showInitialMessage, setShowInitialMessage] = useState(true);

  const [currentSaturation, setCurrentSaturation] = useState(
    COLOR_CONFIGS.BLUE.s,
  );
  const [currentColorConfigItem, setCurrentColorConfigItem] = useState(
    COLOR_CONFIGS.BLUE,
  );

  const [currentSvgIndex, setCurrentSvgIndex] = useState(0);

  const [fillColors, setFillColors] = useState(Array(200).fill('white'));

  const onFillColor = (i) => {
    console.log('called with ', i);
    const newFillColors = fillColors.slice(0);
    newFillColors[i] = currentColor || 'green';
    setFillColors(newFillColors);
  };

  const resetColors = () => {
    setFillColors(Array(200).fill('#ffffff'));
  };

  const onColorChange = (colorConfigItem) => {
    setCurrentColorConfigItem(colorConfigItem);
    setCurrentSaturation(colorConfigItem.s);
  };

  const onSaturationChange = (saturation) => {
    setCurrentSaturation(saturation);
  };

  useEffect(() => {
    // eslint-disable-next-line max-len
    const newColor = `hsl(${currentColorConfigItem.h},${currentSaturation}%,${currentColorConfigItem.l}%)`;
    setCurrentColor(newColor);
  }, [currentColorConfigItem, currentSaturation]);

  useEffect(() => {
    // eslint-disable-next-line max-len
    const newColor = `hsl(${currentColorConfigItem.h},100%,${currentColorConfigItem.l}%)`;
    setCurrentShade(newColor);
  }, [currentColorConfigItem]);

  const onSliderSvgSelected = (index) => {
    setCurrentSvgIndex(index);
    if (index !== currentSvgIndex) {
      resetColors();
    }
    setShowSlider(false);
  };

  const onExit = () => {
    // window.location.replace(REDIRECT_URL);
    window.close();
  };

  const renderGameElements = () => (
    <div className={styles['game-elements-container']}>
      <div className={styles['top-controls-container']}>
        <div
          onClick={onExit}
          className={styles['top-controls-container__exit']}
        >
          Exit
        </div>
        <div
          onClick={onSubmit}
          className={styles['top-controls-container__submit']}
        >
          Submit
        </div>
      </div>

      <img
        alt="top-logo"
        src="/sv-gaming/colorfy/images/top-logo.png"
        className={styles['top-logo']}
      />

      <div className={styles['game-container']}>
        <div className={styles['color-container']}>
          <ColorSelector
            color1={COLOR_CONFIGS.BLUE}
            color2={COLOR_CONFIGS.YELLOW}
            color3={COLOR_CONFIGS.PURPLE}
            color4={COLOR_CONFIGS.RED}
            color5={COLOR_CONFIGS.ORANGE}
            onItemClick={onColorChange}
          />
        </div>
        <div className={styles['canvas-container']}>
          <div className={styles['canvas-container-inner']}>
            <div className={styles['coloring-section']}>
              <ColoringScreen
                onChangeClicked={() => setShowSlider(true)}
                currentColor={currentColor}
                onFillColor={onFillColor}
                onReset={resetColors}
                fillColors={fillColors}
                currentSvgIndex={currentSvgIndex}
                name={`Canvas ${currentSvgIndex + 1}`}
              />
            </div>

            <div className={styles['saturation-section']}>
              <SaturationSlider
                currentSatLevel={currentSaturation}
                currentColor={currentColor}
                onChange={onSaturationChange}
                selectedShade={currentColorConfigItem.name}
                selectedShadeColor={currentShade}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const renderSlider = () => (
    <div className={styles['slider-container']}>
      <ImageSliderScreen
        onBackClick={() => setShowSlider(false)}
        onImageSelected={onSliderSvgSelected}
        initialIndex={currentSvgIndex}
      />
    </div>
  );

  return (
    <>
      {!showSlider && renderGameElements()}

      {showInitialMessage && (
        <InitialMessage
          message="fdaf fsdafdas "
          onOkClicked={() => {
            setShowInitialMessage(false);
            setShowSlider(true);
          }}
        />
      )}

      <CSSTransition
        in={showSlider}
        timeout={200}
        classNames="fade-transition"
        unmountOnExit
      >
        {renderSlider()}
      </CSSTransition>
    </>
  );
}

export default ColoringAndControls;
