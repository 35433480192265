import React ,{ useState } from 'react';
import './assets/styles/FooterNew.css';

import './assets/fonts/Gosha Sans/TTF/GoshaSans-Ultrabold.ttf'
// import FacebookIcon from './assets/svgs/FacebookIcon.js';
import { ModalBody } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import {Container,Row ,Col,Image} from 'react-bootstrap';

import OML from './assets/images/oml.svg';
import Dewars from './assets/images/dewars12.png';
import Breezer from './assets/images/breezer12.png';
import PayTm from './assets/images/insidernew.svg';
import YoutubeIcon from './assets/images/youtubeIcon.png';
import InstagramIcon from './assets/images/InstagramIcon.png';
import FacebookIcon from './assets/images/FacebookIcon.png';
import TwitterIcon from './assets/images/TwitterIcon.png';
import SgIcon from './assets/images/sg.png';
import TinderIcon from './assets/images/tinder.png';
import upIcon from './assets/images/up.png';
import bacardi from './assets/images/bacardi.svg';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom'; 
import VideoPage from './VideoPage.js';

import ContactUs from './ContactUs.js';
import BuyTickets from './BuyTickets.js';
import FAQ from './FAQ.js'
import Home from './Home.js'

export default props => {
	// const [up, setUp] = useState(false);
	const [forMob, setForMob] =  React.useState(false);
	// 	React.useEffect(() => {
	// 		window.scrollTo(0, 0)
	// 	  }, [up])
	
	// const setvalue = function(){
				
	// 	if(up == false) {
	// 		setUp(true)
	// 	}
	// }
	React.useEffect(()=>{
		if(window.innerWidth<526)
			setForMob(true)
		// if(window.innerWidth<426){
		// 	document.querySelector("#footer > div > div.footer-logos.mt-3.row > div.col1.test3.test5.col-xl-6.col-lg-6.col-md-6.col-sm-6 > div > div:nth-child(1) > img").style.marginTop="-5px";
		// }
	},[])
	return (
		<div className="footer-div" id="footer">
		<Container fluid >
			<Row className="mt-3 px-2">
			{/* xs, sm, md, lg, and xl */}
				<Col xl={2} lg={2} sm={3} xs={4} className="col1 pl-3">
					<div className="test">
						{/* <a className="footer-links-col2">LINE-UP</a> */}
						<Link className="footer-links-col2" onClick={()=>{window.scrollTo(500, 0);}} to="/line-up">LINE UP</Link>

					</div>
					<div className="test">
						<Link className="footer-links-col2" onClick={()=>{window.scrollTo(500, 0);}} to="/over-the-years">OVER THE YEARS</Link>
					</div>
					<div className="test">
						<Link className="footer-links-col2" onClick={()=>{window.scrollTo(500, 0);}} to="/faqs">FAQs</Link>
					</div>
					
				</Col>
				<Col xl={2} lg={2} sm={3} xs={4} className="col2">
				
					<div className="test">
						<Link className="footer-links-col2" onClick={()=>{window.scrollTo(500, 0);}} to="/contact">CONTACT</Link>
					</div>
					<div className="test">
						<Link className="footer-links-col2" onClick={()=>{window.scrollTo(500, 0);}} to="/buy-tickets">BUY TICKETS</Link>
					</div>
					
				</Col>
				<Col xl={6} lg={4} sm={3} xs={4} className="coldesc">
					<span className="rights-reserved">@2020 only much louder <br/> all rights reserved</span>
					
				</Col>
			   
				<Col xl={2} lg={4} sm={2} className="col2 uparrow">
					<Image onClick={()=>{window.scrollTo(500, 0);}} fluid width={50} src={upIcon} />
				</Col>
			</Row>


			<Row className="footer-logos mt-3">
				<Col xl={2} lg={2} md={2} sm={2} className="col1  test3 oml-top-logo">
				   
					<div >
						<Image fluid width={60} src={OML} />
					</div>
				</Col>          


				<Col xl={7} lg={7} md={8} sm={8}  id="desktop-footer" className="col1  test3  test5 mb-0" >
					<Row >
					<Col style={{paddingBottom:"10px"}} >
					<Image fluid src={bacardi} width={60}  />
					</Col>
					<Col >
					<Image fluid style={{marginTop:"10px"}} src={Dewars}  width={80} />
					</Col>
					<Col >
					<Image fluid src={Breezer} width={75} />
					</Col>
					{/* <Col >
					<Image fluid src={TinderIcon} width={60}  />
					</Col> */}
					<Col className="test6">
					{/* <Image fluid src={SgIcon}  width={75} /> */}
					<img className="sgIcon" width="120px" height="40px" src={SgIcon} />
					</Col>
					<Col className="test6">
					<img className="paytm" width="120px" height="40px" src={PayTm} />
					</Col>
					</Row>
				</Col>    
				<Row  id="mobile-footer" className=""  style={{marginTop:"5px",margin:"auto"}} >
					<Row >
					<Col style={{paddingBottom:"10px"}} >
					<Image fluid src={bacardi} width={60}  />
					</Col>
					<Col >
					<Image fluid style={{marginTop:"10px"}} src={Dewars}  width={80} />
					</Col>
					<Col >
					<Image fluid src={Breezer} width={75} />
					</Col>
					{/* <Col >
					<Image fluid src={TinderIcon}  width={70} />
					</Col> */}
					
					</Row>
				</Row>    
				<Col xl={6} lg={6} md={6} sm={6}  id="mobile-footer" className="col1  test3  test5 " style={{marginTop:"10px"}} >
					<Row >
					<Col className="test6" style={{textAlign:"center"}} >
					{/* <Image fluid src={SgIcon}  width={75} /> */}
					<img className="sgIcon" width="120px" height="40px" src={SgIcon} />
					</Col>
					<Col className="test6">
					<img className="paytm" width="120px" height="40px" src={PayTm} />
					</Col>
					
					</Row>
				</Col>    
				
				<Col xl={3} lg={3} md={12} sm={12} className="col1  test3 test7" >
					<Row >
					 <Col className="" >
					 <a href="https://www.facebook.com/nh7weekender" target="_blank">
					 <Image fluid width={25} src={FacebookIcon} />
						 </a>
						 
						</Col>
						<Col className="" >
						<a href="https://www.instagram.com/nh7dotin/?hl=en" target="_blank">
						<Image fluid width={25} src={InstagramIcon} />
						</a>
						</Col>
						<Col className="">
						<a href="https://twitter.com/NH7" target="_blank">
							<Image fluid width={23} src={TwitterIcon} />		
						</a>			
						</Col>
						<Col  className="">
						<a href="https://www.youtube.com/channel/UCmuo_6az502F9EtV7Z_ftaw" target="_blank">

						<Image fluid width={40} src={YoutubeIcon} />
						</a>
						
						</Col>
						</Row>
				</Col>    

				<Col xl={2} lg={2} md={12}  sm={12} style={{justifyContent:"center"}} className="col1 mt-4 test3 oml-bottom-logo">
				   
				   <div >
					   <Image fluid width={50} src={OML} />
				   </div>
			   </Col> 

			</Row>
			<Row className="footer-container-custom copyright-info mt-3 mb-4">
					<a className="footer-text">© 2020 BACARDI, THE BAT device and BREEZER are registered trademarks of Bacardi & Company Limited. BACARDI.COM © 2020, DEWARS, its Trade Dress and CELTIO device are trademarks (universalMusic) on Universal Music Group</a>
			
			 </Row>
		</Container>
	</div>
	);
};
