import React, { useEffect, useLayoutEffect, useState } from 'react';
import './App.css';
// eslint-disable-next-line no-unused-vars
import DeviceOrientation, { Orientation } from 'react-screen-orientation';
import { isMobile, isAndroid } from 'react-device-detect';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import Main from './views/Main/Main';
import useWindowSize from './customHooks/useWindowSize';
import LegalAgeChecker from './views/LegalAgeChecker/LegalAgeChecker';
import SoundLoader from './constants/SoundLoader';
import beginSubmissionFlow from './helpers/beginSubmissionFlow';
import { REDIRECT_URL } from './constants';

const IS_ONE_TIME_AGREEMENT = false;

function App() {
  const windowSize = useWindowSize();
  const fullScreenHandle = useFullScreenHandle();

  const [isFullScreen, setIsFullScreen] = useState(false);
  const [hasAgreedTerms, setHasAgreedTerms] = useState(false);

  // -------- sound caching ----------------
  useEffect(() => {
    const loadAudio = async () => {
      await SoundLoader();
    };
    loadAudio();
  }, []);

  useEffect(() => {
    if (
      IS_ONE_TIME_AGREEMENT &&
      localStorage.getItem('is_terms_agreed') &&
      localStorage.getItem('is_terms_agreed') === 'Y'
    ) {
      setHasAgreedTerms(true);
    }
  }, []);

  useLayoutEffect(() => {
    if (windowSize.height) {
      const vh = windowSize.height * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
  }, [windowSize]);

  const setCanvasDims = () => {
    const canvasElement = document.getElementById('drawing_canvas_div');
    if (
      canvasElement &&
      canvasElement.offsetHeight &&
      canvasElement.offsetWidth
    ) {
      const height = canvasElement.offsetHeight;
      const width = canvasElement.offsetWidth;
      document.documentElement.style.setProperty(
        '--canvas-height',
        `${height}px`,
      );
      document.documentElement.style.setProperty(
        '--canvas-width',
        `${width}px`,
      );
    }
  };

  useLayoutEffect(() => {
    if (windowSize.height) {
      // setting the coloring area height and width manually
      setCanvasDims();
    }
  }, [windowSize]);

  // calling this every one sec since useLayout
  // fires unpredictably on full screen / rotation
  useEffect(() => {
    const interval = setInterval(() => {
      setCanvasDims();
    }, 500);

    return () => {
      if (interval) clearInterval(interval);
    };
  }, []);

  // ----------------- submission ----------------

  const onSubmit = (imageDataBlob) => {
    beginSubmissionFlow(imageDataBlob, fullScreenHandle);
  };

  // ----------------- submission ----------------

  const renderGame = () => (
    <div className="container-colorfy-outer">
      <div className="container-colorfy">
        <Main onSubmit={onSubmit} />
      </div>
    </div>
  );

  const renderGameViewWithOrientationCheck = () => (
    <DeviceOrientation lockOrientation="landscape">
      <Orientation orientation="landscape" alwaysRender={false}>
        {renderGame()}
      </Orientation>
      {/* Will stay in DOM, but is only visible in portrait */}
      <Orientation orientation="portrait" alwaysRender={false}>
        <div className="container-portrait">
          <p>Please rotate your device</p>
          <p>You may need to enable auto rotation</p>
        </div>
      </Orientation>
    </DeviceOrientation>
  );

  const renderLandscapeView = () =>
    isMobile ? renderGameViewWithOrientationCheck() : renderGame();

  const renderFullScreenRequestView = (fsHandle) => (
    <div className="container-portrait">
      <p>this game requires fullscreen</p>
      <button type="button" onClick={fsHandle.enter}>
        enable
      </button>
    </div>
  );

  const onLegalAge = () => {
    setHasAgreedTerms(true);
    localStorage.setItem('is_terms_agreed', 'Y');
  };

  const onIllegalAge = () => {
    localStorage.setItem('is_terms_agreed', 'N');
    // window.location.replace(REDIRECT_URL);
    window.close();
  };

  const renderFullScreenLandscapeView = () => (
    <FullScreen
      handle={fullScreenHandle}
      onChange={(state) => setIsFullScreen(state)}
    >
      {!isFullScreen && renderFullScreenRequestView(fullScreenHandle)}
      {isFullScreen && renderLandscapeView()}
    </FullScreen>
  );

  const renderPostTermsAgreed = () => (
    <>
      {!isMobile && renderLandscapeView()}
      {isMobile && !isAndroid && renderLandscapeView()}
      {isMobile && isAndroid && renderFullScreenLandscapeView()}
    </>
  );

  return hasAgreedTerms ? (
    renderPostTermsAgreed()
  ) : (
    <LegalAgeChecker onLegalAge={onLegalAge} onIllegalAge={onIllegalAge} />
  );
}

export default App;
