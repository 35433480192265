import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/styles/Header.css'
import { Container, Row, Col, Image } from 'react-bootstrap';
import navIcon from './assets/images/navicon.png'
import closeIcon from './assets/images/close.png'
// import './assets/styles/Header.css';
import { Link } from "react-router-dom";
  
import Logo from './assets/images/logo.png';
export default () => {
    // const [mobView, setMobView] = React.useState(false);
    const [headerActive, setHeaderActive] = React.useState(false);

    const setNavbarProperties = (elemObj, actionType) => {
        elemObj.style.backgrounColor="yellow";    
        // elemObj.style.position =  actionType?"relative":"fixed";
        if(actionType)
        {
            elemObj.style.top="unset";
            elemObj.style.bottom="0px";
        }
        else
        {
            elemObj.style.bottom="unset";
            elemObj.style.top="0px";
        }
            // elemObj.style.bottom="0px";

        elemObj.style.margin =  "0px";
        elemObj.style.padding ="0px";
        elemObj.style.height = actionType?"unset":"100%";
        elemObj.style.zIndex = "11";
    }
    const handleNavBar = actionType => {
        //console.log('handling navbar');
        if(headerActive&&actionType==0)
            actionType = 1;
        let allLinks = document.getElementsByClassName("mobNavbar");
        for(let x of allLinks[0].children)
        {
            if(x.id!="closeIcon"&&x.id!="revealIcon")
            {
                if(actionType)
                    x.style.display="none";
                else
                    x.style.display="block";
                    
            }
            else{
                if(actionType) //scenario for closing the navbar or when the page loads
                {   if(x.id === "closeIcon") //it will hide the close icon
                        x.style.display="none";
                    else //it will display three lines
                        x.style.display="block";
                }
                else //scenario for revealing the navbar
                {
                    if(x.id === "revealIcon") //it will hide the three lines
                        x.style.display="none";
                    else //it will display the close icon
                        x.style.display="block";
                }
            }
        }
        if(actionType)
        setHeaderActive(false);
        else
        setHeaderActive(true);

        setNavbarProperties(allLinks[0], actionType)
    };

    React.useEffect(()=>{
        if(window.innerWidth<769)
        {
            // setMobView(true);
            handleNavBar(1);
        }
        let revealIcon = document.getElementById("revealIcon");
        let closeIcon = document.getElementById("closeIcon");
        let rev = revealIcon.addEventListener('click', ()=>{handleNavBar(0)})
        let cls = closeIcon.addEventListener('click', ()=>{handleNavBar(1)})
        return ()=>{
            window.removeEventListener('click', rev);
            window.removeEventListener('click', cls);
        }
    },[]);
    
    return (
        <div className="customNavBar" >
            <Container fluid id="navbar-custom">
                <Row className="mt-3 mainRow">
                    <Col xl={4} lg={3} md={2} sm={2} className="mainLogo">
                        <Link to="/"><Image fluid src={Logo} width={120} /></Link>
                    </Col>

                    <Col xl={8} lg={9} md={10} sm={11} className="item-list">
                        <Row className="navbarItems">
                            {/* <li className="Navbar-Link-li"><a>HOME</a></li> */}
    						<li className="Navbar-Link-li"><span id="home" onClick={()=>handleNavBar(0)}><Link to="/">HOME</Link></span></li>
                            <li className="Navbar-Link-li"><span id="line-up"  onClick={()=>handleNavBar(0)}><Link to="line-up" id="lineup-link"> LINE UP</Link></span></li>
                            <li className="Navbar-Link-li"><span id="over-the-year"onClick={()=>handleNavBar(0)} > <Link id="over-the-year-link"  to="/over-the-years">OVER THE YEARS</Link> </span></li>
                            <li className="Navbar-Link-li"><span id="faqs" onClick={()=>handleNavBar(0)}><Link id="faqs-link" to="/faqs">FAQS</Link></span></li>
                            <li className="Navbar-Link-li"><span id="contact"onClick={()=>handleNavBar(0)} ><Link id="contact-link" to="/contact">CONTACT</Link></span></li>
                            <li className="Navbar-Link-li"><span id="buy_ticket" onClick={()=>handleNavBar(0)}><Link id="buy_ticket-link" to="/buy-tickets">BUY TICKET</Link></span></li>


                        </Row>
                    </Col>

                </Row>
                <Row>
                    <Col className="mobNavbar revealItems">
                        <Row id="closeIcon" className=" pt-4 align-content " >
                            <Image fluid width={30} src={closeIcon} />
                        </Row>
                        <Row className=" mt-5 align-content">
                            {/* <li className="Navbar-Link-li">HOME</li> */}
    						<li className="Navbar-Link-li"><span id="home-mob" ><Link onClick={()=>{handleNavBar(1)}} to="/">HOME</Link></span></li>
                        </Row>
                        <Row className="align-content mt-3">
                            <li className="Navbar-Link-li"><span id="line-up" ><Link onClick={()=>{handleNavBar(1)}} id="lineup-link-mob" to="/line-up">LINE UP</Link></span></li>
                        </Row>
                        <Row className="align-content mt-3">
                            {/* <li className="Navbar-Link-li">OVER THE YEARS</li> */}
                            <li className="Navbar-Link-li"><span id="over-the-year-mob"> <Link onClick={()=>{handleNavBar(1)}} id="over-the-year-link-mob"  to="/over-the-years">OVER THE YEARS</Link> </span></li>
                            
                        </Row>
                        <Row className="align-content mt-3">
                            {/* <li className="Navbar-Link-li">FAQS</li> */}
                            <li className="Navbar-Link-li"><span id="faqs-mob" ><Link onClick={()=>{handleNavBar(1)}} id="faqs-link-mob" to="/faqs">FAQS</Link></span></li>

                        </Row >
                        <Row className="align-content mt-3">
                            {/* <li className="Navbar-Link-li">CONTACT</li> */}
                            <li className="Navbar-Link-li"><span id="contact-mob"><Link onClick={()=>{handleNavBar(1)}}  id="contact-link-mob" to="/contact">CONTACT</Link></span></li>

                        </Row>
                        <Row className="align-content mt-3">
                            {/* <li className="Navbar-Link-li">BUY TICKET</li> */}
                            <li className="Navbar-Link-li"><span id="buy_ticket-mob"><Link  onClick={()=>{handleNavBar(1)}} id="buy_ticket-link-mob" to="/buy-tickets">BUY TICKET</Link></span></li>

                        </Row>

                        <Row id="revealIcon" className="mt-3 pb-5 align-content">
                            <Image fluid width={40} src={navIcon} />
                        </Row>
                    </Col>
                </Row>
            </Container>


        </div>
    )
}