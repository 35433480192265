import React from 'react'

const Board = props => {
    const drop = e => {
        e.preventDefault();
        const card_id = e.dataTransfer.getData('card_id');
       
        const card = document.getElementById(card_id);

        for(let i in props.targetCards)
        {
            // console.log(`props.pairCards[${i}] : ${props.targetCards[i]}`)
            if(props.targetCards[i] === card_id)
            {
                let player = document.getElementById("audio");
                player.style.display = "block";
                let ae = document.getElementById("audio-element");  
                ae.play();
               // alert(`${card_id} ---> ${props.id}`);
                
            }

        }
        card.style.display = 'block';
    }
    const onTouchEnd = e => {
        // console.log(e.target);
        console.log(e);
        // var myLocation = e.changedTouches[0];

        // var realTarget = document.elementFromPoint(myLocation.clientX, myLocation.clientY);
        // let target_id  =  realTarget.id;
        for(let i in props.targetCards)
        {
            // console.log(`props.pairCards[${i}] : ${props.targetCards[i]}`)
            if(props.targetCards[i] === props.activeCard)
            {
                let player = document.getElementById("audio");
                player.style.display = "block";
                let ae = document.getElementById("audio-element");  
                ae.play();
                
               // alert(`${card_id} ---> ${props.id}`);
                
            }

        }
        props.setActiveCard('');
        // props.setColor("aquamarine", props.targetBoards);

        // props.setActiveCard(target_id);
        // realTarget.style.backgroundColor="red";
        // console.log
    }
    const dragOver = e => {
        e.preventDefault();
    }    

    const onTouchMoveCapture = e => {
        console.log(e.target);
    }
    return (
        props.mobView?
        <div
            id={props.id}
            className={props.className}
            // onDrop = {drop}
            // onDragOver = {dragOver}
            // onTouchEndCapture = {onTouchMoveCapture}
            // onPointerOver = {onTouchMoveCapture}
            onTouchEnd = {onTouchEnd}
            // onTouchEnd = {onTouchMoveCapture}
            // onTouchMoveCapture = {onTouchMoveCapture}
        >
            <span>{props.name}</span>
        </div>
        :
        <div
            id={props.id}
            className={props.className}
            onDrop = {drop}
            onDragOver = {dragOver}
            // onTouchEndCapture = {onTouchMoveCapture}
            // onPointerOver = {onTouchMoveCapture}
            // onTouchEnd = {onTouchEnd}
            // onTouchEnd = {onTouchMoveCapture}
            // onTouchMoveCapture = {onTouchMoveCapture}
        >
            <span>{props.name}</span>
        </div>
    )
}


export default Board