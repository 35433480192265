import React, { useState } from 'react';

import styles from './styles.module.css';

export default function RecipeScreen({ onBackClicked }) {
  const [loading, setLoading] = useState(true);

  const getContainerClass = () => {
    if (loading)
      return `${styles.recipeContainer} ${styles['recipeContainer--without-scroll']}`;
    if (!loading)
      return `${styles.recipeContainer} ${styles['recipeContainer--with-scroll']}`;
  };
  return (
    <div className={styles.container}>
      {/* back button */}
      <div onClick={onBackClicked} className={styles.backBtn}>
        <div className={styles['inner-back-btn']}>
          <p className={styles.backBtnText}>BACK</p>
        </div>
      </div>

      {/* Receipes */}
      <div className={getContainerClass()}>
        {loading && (
          <div className={styles['loading-container']}>Loading...</div>
        )}
        <img
          alt="recipes"
          src="/sv-gaming/bacardi/assets/images-bacardi/bacadi-recipe.png"
          className={styles['recipes-img']}
          onLoad={() => setLoading(false)}
        />
      </div>
    </div>
  );
}
