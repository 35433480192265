import React from 'react';
import { dw15 } from '../../constants/ShelfConfig';
import styles from './styles.module.css';

function DW15({ enabled, onClick }) {
  return (
    <img
      onClick={() => enabled && onClick(dw15)}
      src={dw15.src}
      alt="extra-bottle"
      className={`${styles['extra-bottle']} ${
        enabled ? '' : styles['extra-bottle--faded']
      }`}
    />
  );
}

export default DW15;
