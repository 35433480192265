import React, { useEffect, useState } from 'react';
import './fadeTransition.css';
import { Howl } from 'howler';
import GameScreen from '../GameScreen/GameScreen';
import styles from './styles.module.css';
import Loading from '../Loading/Loading';
import ImageLoader from '../../constants/ImageLoader';

function Main({ onSubmit }) {
  const sound = new Howl({
    src: ['/sv-gaming/colorfy/sounds/bg.wav'],
    loop: true,
    volume: 0.1,
  });

  const [isImageAssetsLoading, setIsImageAssetsLoading] = useState(true);

  useEffect(() => {
    const loadImage = async () => {
      await ImageLoader();
      console.log('images loaded');
      setIsImageAssetsLoading(false);
    };
    loadImage();
  }, []);

  useEffect(() => {
    if (!isImageAssetsLoading) sound.play();

    return () => sound.stop();
  }, [isImageAssetsLoading]);

  return (
    <div className={styles.container}>
      {isImageAssetsLoading ? <Loading /> : <GameScreen onSubmit={onSubmit} />}
    </div>
  );
}

export default Main;
