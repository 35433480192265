const CardData = [
    {cardName:"FIRST SONG", key:"1", className:"card", targetBoards:["F SNG"], get id(){return this.cardName}},
    {cardName:"SECOND SONG", key:"2", className:"card", targetBoards:["F SNG", "S SNG"], get id(){return this.cardName}},
    {cardName:"THIRD SONG", key:"3", className:"card", targetBoards:["F SNG", "T SNG"], get id(){return this.cardName}},
    {cardName:"FOURTH SONG", key:"4", className:"card", targetBoards:["R SNG"], get id(){return this.cardName}},
    {cardName:"FIFTH SONG", key:"5", className:"card", targetBoards:["R SNG", "Q SNG"], get id(){return this.cardName}}


];
const BoardData = [
    {boardName:"F SNG", key:"1", pairCards:["FIRST SONG", "SECOND SONG", "THIRD SONG"], className:"board", get id(){return this.boardName}},
    {boardName:"S SNG", key:"2", pairCards:["SECOND SONG"], className:"board", get id(){return this.boardName}},
    {boardName:"T SNG", key:"3", pairCards:["THIRD SONG"], className:"board", get id(){return this.boardName}},
    {boardName:"R SNG", key:"4", pairCards:["FOURTH SONG", "FIFTH SONG"], className:"board", get id(){return this.boardName}},
    {boardName:"Q SNG", key:"5", pairCards:["FIFTH SONG"], className:"board", get id(){return this.boardName}}


];

module.exports.CardData = CardData;
module.exports.BoardData = BoardData;