import React from 'react';
import styles from './styles.module.css';

function StartScreenButton({
  onClick = () => console.log('button clicked'),
  isLower,
  text = 'START',
}) {
  return (
    <div
      onClick={onClick}
      className={`${styles.container} ${
        isLower ? styles['container--lower'] : styles['container--upper']
      }`}
    >
      <div className={styles['valve-container']}>
        <img
          alt="valve"
          className={styles.valve}
          src="/sv-gaming/dewars/valve.png"
        />
      </div>

      <div className={styles['text-container']}>
        <div className={styles['text-box-wrapper']}>
          <div className={styles['text-box']}>{text}</div>
        </div>
      </div>
    </div>
  );
}

export default StartScreenButton;
