export const dw12 = {
  src: '/sv-gaming/dewars/dw-12.png',
  name: 'dw12',
  color: '#c49618',
};
export const dw15 = {
  src: '/sv-gaming/dewars/dw-15.png',
  name: 'dw15',
  color: '#c49618',
};

export const glassTall = {
  src: '/sv-gaming/dewars/glass-tall.png',
  name: 'glass-tall',
  color: '#c49618',
};

export const soda = {
  src: '/sv-gaming/dewars/soda.png',
  name: 'soda',
  color: '#ded9cc',
};

export const ale = {
  src: '/sv-gaming/dewars/ale.png',
  name: 'ale',
  color: '#ded9cc',
};

export const coconutConfig = {
  src: '/sv-gaming/dewars/coconut.png',
  name: 'coconut',
  color: '#afdade',
};

export const TopShelfRow = [
  dw12,
  dw12,

  dw12,

  dw12,
  dw12,
  dw12,

  dw12,

  dw12,
  dw12,
  dw12,

  dw12,
  dw12,
  dw12,

  dw12,
  dw12,
  dw12,
  dw12,
  dw12,
  dw12,
  dw12,

  dw12,

  dw12,
];
export const BottomShelfRow = [
  dw12,
  dw12,
  dw12,
  dw12,
  dw12,
  dw12,
  dw12,
  dw12,

  dw12,
  dw12,
  dw12,

  dw12,
  dw12,
];
