import React from 'react';
import styles from './styles.module.css';

function TwoThirds({ color = 'white' }) {
  return (
    <svg
      className={styles['level-svg']}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 34.133 56.267"
    >
      <path
        fill={color}
        d="M-1837.667,351.467h34.133v51.467s-7.733,4.8-15.467,4.8-16.8-4.8-16.8-4.8Z"
        transform="translate(1837.667 -351.467)"
      />
    </svg>
  );
}

export default TwoThirds;
