import React from 'react';
import Full from './Full';
import OneThird from './OneThird';
import styles from './styles.module.css';
import TwoThirds from './TwoThirds';

function GlassLevel({
  currentLevel = 1,
  // eslint-disable-next-line no-unused-vars
  totalLevels = 3,
  currentColor = 'blue',
}) {
  const renderSvg = () => {
    if (currentLevel === 0) {
      return <></>;
    }
    if (currentLevel === 1) {
      return <OneThird color={currentColor} />;
    }
    if (currentLevel === 2) {
      return <TwoThirds color={currentColor} />;
    }
    if (currentLevel === 3) {
      return <Full color={currentColor} />;
    }
  };

  return <div className={styles.container}>{renderSvg()}</div>;
}

export default GlassLevel;
