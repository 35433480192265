import React from 'react';
import styles from './styles.module.css';

function ResultScreen({
  status: { isWinner, isTimeUp },
  onBackSelected,
  onReplaySelected,
}) {
  const points = localStorage.getItem('bacardi_point');
  const totalPoints = localStorage.getItem('bar_game_total_point');

  const getMessage = () => {
    if (isWinner) return 'YOU GOT THE RIGHT MIX!';
    if (isTimeUp) return 'OOPS! YOU RAN OUT OF TIME!';
    return 'Yieks! Looks like you did not mix that up right';
  };

  return (
    <div className={styles.container}>
      <div className={styles['middle-container']}>
        <div
          className={`${styles['middle-container__feedback']} ${
            isWinner
              ? styles['middle-container__feedback--winner']
              : styles['middle-container__feedback--time-up']
          }`}
        >
          {getMessage()}
        </div>
        <div className={styles['middle-container__points']}>
          POINTS - {points}
        </div>
        <div className={styles['middle-container__totalPoint']}>
          TOTAL POINTS - {totalPoints}
        </div>

        <div className={styles['middle-container__default']}>
          COLLECT 1000 POINTS AND GET A CHANCE TO WIN A FESTIVAL KIT
        </div>

        <div className={styles['actions-container']}>
          <div
            onClick={onBackSelected}
            className={styles['action-container__button']}
          >
            Back
          </div>
          <div
            onClick={onReplaySelected}
            className={styles['action-container__button']}
          >
            Replay
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResultScreen;
