import React from 'react';
import { orangeJuice } from '../../constants/ShelfConfig';
import styles from './styles.module.css';

function OrangeJuice({ enabled, onClick }) {
  return (
    <img
      onClick={() => enabled && onClick(orangeJuice)}
      src={orangeJuice.src}
      alt="extra-bottle"
      className={`${styles['extra-bottle']} ${
        enabled ? '' : styles['extra-bottle--faded']
      }`}
    />
  );
}

export default OrangeJuice;
