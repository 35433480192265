import React from 'react';
import StepProgressStatus from '../../components/StepProgressStatus/StepProgressStatus';
import styles from './styles.module.css';

function StepProgressStatusContainer({
  levels = 5,
  currentStatus = [true, true, false],
}) {
  return (
    <div className={styles.container}>
      <StepProgressStatus
        levels={levels}
        currentStatus={currentStatus}
        width="100%"
        height="100%"
      />
    </div>
  );
}

export default StepProgressStatusContainer;
