import React from 'react'

export default props => {
    const dragStart = e => {
        e.dataTransfer.setData('card_id',e.target.id)
        props.setColor("green", props.targetBoards);
        // setTimeout(()=> {e.target.style.display = "none";},0);
    }
    const touchStart = e => {
        props.setColor("green", props.targetBoards);
    }
    const onTouchMove = e => {
        // console.log(`e : ${JSON.stringify(e.target)}`)
        console.log(e);
        // var myLocation = e.changedTouches[0];
        // console.log(`x : ${myLocation.clientX}`)
        // console.log(`y : ${myLocation.clientY}`)

        // e.target.style.left = myLocation.clientX+"px";
        // e.target.style.top = myLocation.clientY+"px";

        // var realTarget = document.elementFromPoint(myLocation.clientX, myLocation.clientY);
        // e.target.style.display = "none";
        console.log(e.target);
        // e.dataTransfer.setData('card_id',e.target.id)
        // props.setColor("green", props.targetBoards);
        // setTimeout(()=> {e.target.style.display = "none";},0);
    }
    const onTouchEnd = e => {
        // console.log(e.target);
        console.log(e);
        var myLocation = e.changedTouches[0];

        var realTarget = document.elementFromPoint(myLocation.clientX, myLocation.clientY);
        // alert(`${realTarget.tagName}`)
        if(realTarget.hasAttribute('isClickable'))
        {
            if(realTarget.tagName==="SPAN") 
            realTarget = realTarget.parentElement;
            let target_id  =  realTarget.id;
            props.setActiveCard(target_id);
            if(realTarget.style.backgroundColor!="red")
            {
                // console.log('Already Red!');
                realTarget.style.backgroundColor="red";
                if(target_id === "FIRST SONG")
                {
                    console.log(`${JSON.stringify(props.targetBoards)}`)
                }
                props.setColor("green", props.targetBoards);
            }
            else
            {
                // console.log('Already Red!')
                realTarget.style.backgroundColor="orange";
                props.setActiveCard('');
            }
            
        }
        

        // console.log
    }
    const displayCardAgain = e => {
        e.target.style.display = "block";
        props.setColor("aquamarine", props.targetBoards);

    };
    
    const dragOver = e => {
        
        e.stopPropagation();
    }
    return (
        props.mobView?
        <div
            id={props.id}
            className={props.className}
            draggable = {props.draggable}
            // onDragStart = {dragStart}
            onTouchEnd = {onTouchEnd}
            // onDragEnd = {displayCardAgain}
            // onTouchStart={touchStart}
            // onTouchEnd = {onTouchEnd}
            // onTouchMove={onTouchMove}
            // onDragOver = {dragOver}
            isclickable="true"
        >
            <span isclickable="true">{props.name}</span>
        </div>
        :
        <div
            id={props.id}
            className={props.className}
            isclickable="true"
            draggable = {props.draggable}
            onDragStart = {dragStart}
            // onTouchEnd = {onTouchEnd}
            onDragEnd = {displayCardAgain}
            // onTouchStart={touchStart}
            // onTouchEnd = {onTouchEnd}
            // onTouchMove={onTouchMove}
            onDragOver = {dragOver}
        >
            <span isclickable="true">{props.name}</span>
        </div>
    )
}