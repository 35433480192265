import React from 'react';
import styles from './styles.module.css';
import { coconut } from '../../constants/ShelfConfig';

function Coconut({ enabled, onClick }) {
  return (
    <img
      onClick={() => enabled && onClick(coconut)}
      src="/sv-gaming/bacardi/assets/images/coconut.png"
      alt="ice"
      className={`${styles.ice} ${enabled ? '' : styles['ice--faded']}`}
    />
  );
}

export default Coconut;
