export const bacardiRum = {
  src: '/sv-gaming/bacardi/assets/images-bacardi/bacardi-rum.png',
  name: 'bacardiRum',
  color: '#dff4f5',
};
export const pineJuice = {
  src: '/sv-gaming/bacardi/assets/images-bacardi/pine-juice.png',
  name: 'pineJuice',
  color: '#ffce35',
};

export const lemonJuice = {
  src: '/sv-gaming/bacardi/assets/images-bacardi/lemon-juice.png',
  name: 'lemonJuice',
  color: '#fcd34d',
};

export const orangeJuice = {
  src: '/sv-gaming/bacardi/assets/images-bacardi/orange-juice.png',
  name: 'orangeJuice',
  color: '#ffaf4d',
};

export const soda = {
  src: '/sv-gaming/bacardi/assets/images-bacardi/soda.png',
  name: 'soda',
  color: '#ded9cc',
};

export const cola = {
  src: '/sv-gaming/bacardi/assets/images-bacardi/cola.png',
  name: 'cola',
  color: '#874931',
};

export const glassTall = {
  src: '/sv-gaming/bacardi/assets/images/glass-tall.png',
  name: 'glass-tall',
  color: '#c49618',
};

export const coconut = {
  src: '/sv-gaming/bacardi/assets/images/coconut.png',
  name: 'coconut',
  color: '#dff4f5',
};

export const TopShelfRow = [
  bacardiRum,
  bacardiRum,
  bacardiRum,
  bacardiRum,
  bacardiRum,
  bacardiRum,
  bacardiRum,
  bacardiRum,
  bacardiRum,
  bacardiRum,
  bacardiRum,
  bacardiRum,
  bacardiRum,
  bacardiRum,
  bacardiRum,
  bacardiRum,
  bacardiRum,
  bacardiRum,
];
export const BottomShelfRow = [
  bacardiRum,
  bacardiRum,
  bacardiRum,
  bacardiRum,
  bacardiRum,
  bacardiRum,
  bacardiRum,
  bacardiRum,
  bacardiRum,
  bacardiRum,
  bacardiRum,
  bacardiRum,
  bacardiRum,
  bacardiRum,
  bacardiRum,
  bacardiRum,
  bacardiRum,
];
