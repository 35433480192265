/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
import React from 'react';
import styles from './styles.module.css';
import { TopShelfRow, BottomShelfRow } from '../../constants/ShelfConfig';

function ShelfItems({ enabled = false, onItemSelected }) {
  const onSelect = (data = '') => {
    enabled && onItemSelected(data);
  };

  const renderShelfItem = (data) => {
    return data.map((item, i) => {
      return (
        <img
          key={`${i + item.name}`}
          alt="test"
          className={styles['shelf-item']}
          src={item.src}
          onClick={() => onSelect(item)}
        />
      );
    });
  };

  return (
    <>
      <div
        className={`${styles['shelf-row']} ${styles['shelf-row--upper']} ${
          enabled ? '' : styles['shelf-row--faded']
        }`}
      >
        {renderShelfItem(TopShelfRow)}
      </div>
      <div
        className={`${styles['shelf-row']} ${styles['shelf-row--lower']} ${
          enabled ? '' : styles['shelf-row--faded']
        }`}
      >
        {renderShelfItem(BottomShelfRow)}
      </div>
      <div />
    </>
  );
}

export default ShelfItems;
