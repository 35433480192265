import React from 'react';
import styles from './styles.module.css';

function StartScreen({ onClick = () => console.log('start clicked') }) {
  return (
    <div className={styles.container}>
      <img
        alt="game-main"
        className={styles['game-image']}
        src="/sv-gaming/colorfy/images/bg-start.png"
      />
      <button
        onClick={onClick}
        type="button"
        className={styles['start-button']}
      >
        Start
      </button>
    </div>
  );
}

export default StartScreen;
