import React from 'react';
import styles from './styles.module.css';

function Coconut({ enabled, onClick }) {
  return (
    <img
      onClick={() => enabled && onClick()}
      src="/sv-gaming/dewars/coconut.png"
      alt="ice"
      className={`${styles.ice} ${enabled ? '' : styles['ice--faded']}`}
    />
  );
}

export default Coconut;
