import React from 'react';
import styles from './styles.module.css';

function RewardScreen({ onOkSelected }) {
  const points = localStorage.getItem('bacardi_point');
  const totalPoints = localStorage.getItem('bar_game_total_point');

  return (
    <div className={styles.container}>
      <div className={styles['middle-container']}>
        <div className={`${styles['middle-container__feedback']}`}>
          CONGRATULATIONS, YOU HAVE COLLECTED 1000 POINTS!
        </div>
        <div className={styles['middle-container__points']}>
          POINTS - {points}
        </div>
        <div className={styles['middle-container__totalPoint']}>
          TOTAL POINTS - {totalPoints}
        </div>

        <div className={styles['middle-container__default']}>
          SUBMIT YOUR DETAILS FOR A CHANCE TO WIN A FESTIVAL KIT.
        </div>

        <div className={styles['actions-container']}>
          <div
            onClick={onOkSelected}
            className={styles['action-container__button']}
          >
            SUBMIT
          </div>
        </div>
      </div>
    </div>
  );
}

export default RewardScreen;
