import React from 'react';
import styles from './styles.module.css';

function AllotedRecipeScreen({ recipe, onOkClicked }) {
  const getMessage = () => {
    return `You have 60 seconds to mix a ${recipe}. Let's get mixing!`;
  };

  return (
    <div className={styles.container}>
      <div className={styles['middle-container']}>
        <div className={styles['middle-container__points']}>{recipe}</div>

        <div className={styles['middle-container__feedback']}>
          {getMessage()}
        </div>

        <div className={styles['actions-container']}>
          <div
            onClick={onOkClicked}
            className={styles['action-container__button']}
          >
            Ok
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllotedRecipeScreen;
