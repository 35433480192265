/* eslint-disable no-use-before-define */
/* eslint-disable import/order */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import useInterval from 'react-useinterval';
import BgImage from '../BgImage/BgImage';
import { Howl } from 'howler';
import Confetti from '../../components/react-dom-confetti/src/confetti';
import CancelSelectionButton from '../CancelSelectionButton/CancelSelectionButton';
import Glass from '../Glass/Glass';
import Leaf from '../Leaf/Leaf';
import LeftControls from '../LeftControls/LeftControls';
import Lemon from '../Lemon/Lemon';
import Loading from '../Loading/Loading';
import RightControls from '../RightControls/RightControls';
import SelectedBottle from '../SelectedBottle/SelectedBottle';
import SelectedExtra from '../SelectedExtra/SelectedExtra';
import ShelfItems from '../ShelfItems/ShelfItems';
import Stirrer from '../Stirrer/Stirrer';
import {
  LEAF_SELECTED_URL,
  LEMON_SELECTED_URL,
  LEMON_WITH_LEAF_URL,
  LEMON_TWIST_URL,
} from './constants';
import styles from './styles.module.css';
import RecipeScreen from '../RecipeScreen/RecipeScreen';
import BackButton from '../BackButton/BackButton';
import './fadeTransition.css';
import FirstScreenControls from '../FirstScreenControls/FirstScreenControls';
import FirstScreenBranding from '../FirstScreenBranding/FirstScreenBranding';
import ScoreBoard from '../ScoreBoard/ScoreBoard';
import RecipeNameBanner from '../RecipeNameBanner/RecipeNameBanner';
import CircularTimer from '../CircularTimer/CircularTimer';
import Coconut from '../Coconut/Coconut';
import { coconutConfig } from '../../constants/ShelfConfig';
import DW12 from '../DW12/DW12';
import DW15 from '../DW15/DW15';
import Soda from '../Soda/Soda';
import Ale from '../Ale/Ale';
import AssetsLoader from '../../constants/AssetsLoader';

import {
  getCurrentGlassColor,
  getCurrentGlassLevel,
  getCurrentStatus,
  getRandomRecipeKey,
  RECIPE_CONFIG,
} from './recipeService';
import ResultScreen from '../ResultScreen/ResultScreen';
import AllotedRecipeScreen from '../AllotedRecipeScreen/AllotedRecipeScreen';
import BackConfirmation from '../BackConfirmation/BackConfirmation';
// eslint-disable-next-line max-len
import StepProgressStatusContainer from '../StepProgressStatusContainer/StepProgressStatusContainer';
import GlassLevel from '../GlassLevel/GlassLevel';
import RewardScreen from '../RewardScreen/RewardScreen';
import LemonTwist from '../LemonTwist/LemonTwist';
import LemonWithLeaf from '../LemonWithLeaf/LemonWithLeaf';

const areAllElementsTrue = (arr) => {
  let retVal = true;
  for (let i = 0; i < arr.length; i += 1) {
    if (retVal && !arr[i]) {
      retVal = false;
    }
  }

  return retVal;
};

const config = {
  angle: '186',
  spread: '360',
  startVelocity: 40,
  elementCount: '200',
  dragFriction: 0.12,
  duration: 3000,
  stagger: '4',
  width: '10px',
  height: '10px',
  perspective: '500px',
  colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
};

function Main({ onSubmit }) {
  const sound = new Howl({
    src: ['/sv-gaming/dewars/assets/background.wav'],
    loop: true,
    volume: 0.4,
  });

  const beepSound = new Howl({
    src: ['/sv-gaming/dewars/assets/beep.wav'],
    volume: 0.1,
  });
  const WinSound = new Howl({
    src: ['/sv-gaming/dewars/assets/Bacardi_Win.wav'],
    volume: 0.8,
  });
  const giftPoints = new Howl({
    src: ['/sv-gaming/dewars/assets/1000-points.wav'],
    volume: 0.8,
  });
  const LoseSound = new Howl({
    src: ['/sv-gaming/dewars/assets/Bacardi_Lose.wav'],
    volume: 0.8,
  });

  const [isZoomed, setIsZoomed] = useState(false);
  const [isAssetsLoaded, setIsAssetsLoaded] = useState(true);
  const [currentItemSelected, setCurrentItemSelected] = useState('');
  const [currentSteps, setCurrentSteps] = useState([]);
  const [counter, setCounter] = useState(60);
  const [selectedBottleData, setSelectedBottleData] = useState({});
  // bottle
  const [isBottleSelected, setIsBottleSelected] = useState(false);
  const [selectedBottleUrl, setSelectedBottleUrl] = useState('');
  const [isPouring, setIsPouring] = useState(false);
  const [isActionOngoing, setIsActionOngoing] = useState(false);
  const [isExtraSelected, setIsExtraSelected] = useState(false);
  const [isDropping, setIsDropping] = useState(false);
  const [selectedExtraUrl, setSelectedExtraUrl] = useState('');
  const [isStirring, setIsStirring] = useState(false);

  const [showResultsScreen, setShowResultsScreen] = useState(false);
  const [showRewardScreen, setShowRewardScreen] = useState(false);
  const [showAllotedRecipeScreen, setShowAllotedRecipeScreen] = useState(false);

  const [currentRecipeKey, setCurrentRecipeKey] = useState(
    getRandomRecipeKey(),
  );
  const [showBackConfirmation, setShowBackConfirmation] = useState(false);
  const [resultStatus, setResultStatus] = useState({
    isTimeUp: false,
    isWinner: false,
  });

  // screen
  const [showRecipeScreen, setShowRecipeScreen] = useState(false);

  const setCurrentGameSteps = (data) => {
    const steps = [...currentSteps];
    steps.push(data);
    setCurrentSteps(steps);
  };

  const setRandomRecipe = () => {
    setCurrentRecipeKey(getRandomRecipeKey());
  };

  useEffect(() => {
    if (!isAssetsLoaded) sound.play();

    return () => {
      sound.stop();
    };
  }, [isAssetsLoaded]);

  useEffect(() => {
    const load = async () => {
      const isloaded = await AssetsLoader();
      if (isloaded) {
        setIsAssetsLoaded(false);
      }
    };
    load();
  }, []);

  useEffect(() => {
    if (isZoomed) {
      setRandomRecipe();
    }
  }, [isZoomed]);

  // set point to local storage
  const submitPoint = (point, totalPoint) => {
    localStorage.setItem('dewars_time', Math.floor(60 - counter));
    localStorage.setItem('dewars_point', point);
    localStorage.setItem('bar_game_total_point', totalPoint);
  };

  const checkForWinOrReset = () => {
    const currentStatus = getCurrentStatus(
      RECIPE_CONFIG[currentRecipeKey].id,
      currentSteps,
    );

    if (currentStatus.length !== RECIPE_CONFIG[currentRecipeKey].steps) return;

    if (areAllElementsTrue(currentStatus)) {
      setTimeout(() => {
        onWin();
      }, 500);
    } else {
      setTimeout(() => {
        beepSound.play();
        setCurrentSteps([]);
      }, 500);
    }
  };

  // to check to winner else reset the steps
  useEffect(() => {
    checkForWinOrReset();
  }, [currentSteps]);

  // timer function
  const decreaseTimeLeft = () => {
    if (!counter) return;
    if (isZoomed && !isAssetsLoaded && !showAllotedRecipeScreen) {
      setCounter((c) => {
        return c > 0 ? c - 1 : c;
      });
    }
  };

  useInterval(decreaseTimeLeft, 1000);

  const onBottleSelected = (data) => {
    if (!isBottleSelected && !isExtraSelected && !isStirring) {
      setIsBottleSelected(true);
      setSelectedBottleUrl(data.src);
      setSelectedBottleData(data);
      setCurrentItemSelected(data.name);
    }
  };

  const onCancelSelection = () => {
    setIsBottleSelected(false);
    setSelectedBottleUrl('');
    setIsExtraSelected(false);
    setSelectedExtraUrl('');
  };

  const updateGameStep = () => {
    setCurrentGameSteps(currentItemSelected);
    setCurrentItemSelected('');
  };

  const onPouringComplete = () => {
    updateGameStep();
    setIsBottleSelected(false);
    setIsPouring(false);
    setSelectedBottleUrl('');
    setIsActionOngoing(false);
  };

  const onDroppingComplete = () => {
    updateGameStep();
    setIsExtraSelected(false);
    setIsDropping(false);
    setSelectedExtraUrl('');
    setIsActionOngoing(false);
  };

  const onStirComplete = () => {
    setIsStirring(false);
    setIsActionOngoing(false);
    setCurrentGameSteps('stir');
  };

  const onDropOrPourClicked = () => {
    if (isBottleSelected) {
      setIsActionOngoing(true);
      setIsPouring(true);
    }
    if (isExtraSelected) {
      setIsActionOngoing(true);
      setIsDropping(true);
    }
  };

  const onStirClicked = () => {
    if (!isBottleSelected && !isExtraSelected && !isStirring) {
      setIsActionOngoing(true);
      setIsStirring(true);
      setCurrentItemSelected('');
    }
  };

  const onResetClicked = () => {
    if (!isBottleSelected && !isExtraSelected && !isStirring) {
      beepSound.play();
      setCurrentSteps([]);
    }
  };

  const onExtraSelected = (imgUrl, type) => {
    setCurrentItemSelected(type);
    setIsExtraSelected(true);
    setSelectedExtraUrl(imgUrl);
  };

  const reset = () => {
    setIsZoomed(false);

    setCurrentItemSelected('');
    setCurrentSteps([]);
    setCounter(60);
    setSelectedBottleData({});
    setIsBottleSelected(false);
    setSelectedBottleUrl('');
    setIsPouring(false);
    setIsActionOngoing(false);
    setIsExtraSelected(false);
    setIsDropping(false);
    setSelectedExtraUrl('');
    setIsStirring(false);
    setShowRecipeScreen(false);
    setShowResultsScreen(false);
    setShowAllotedRecipeScreen(false);
    setShowBackConfirmation(false);
    setResultStatus({ isTimeUp: false, isWinner: false });
  };

  const replayReset = () => {
    setRandomRecipe();
    setCurrentSteps([]);
    setCounter(60);
    setSelectedBottleData({});
    setIsBottleSelected(false);
    setSelectedBottleUrl('');
    setIsPouring(false);
    setIsActionOngoing(false);
    setIsExtraSelected(false);
    setIsDropping(false);
    setSelectedExtraUrl('');
    setIsStirring(false);
    setShowRecipeScreen(false);
    setShowResultsScreen(false);
    setShowAllotedRecipeScreen(true);
    setShowBackConfirmation(false);
    setResultStatus({ isTimeUp: false, isWinner: false });
  };

  const onBackClick = () => {
    const check = !isExtraSelected && !isBottleSelected && !isStirring;
    if (!check) return;
    reset();
  };

  const onWin = () => {
    const totalPoint = localStorage.getItem('bar_game_total_point') || 0;
    const point = Math.floor((counter * 10) / 2);
    const newTotalPoint = parseInt(totalPoint, 10) + parseInt(point, 10);
    setResultStatus({ isTimeUp: false, isWinner: true });
    submitPoint(point, newTotalPoint);

    if (newTotalPoint && newTotalPoint >= 1000) {
      giftPoints.play();
      setShowRewardScreen(true);
      reset();
    } else {
      WinSound.play();
      setShowResultsScreen(true);
    }
  };

  const onRecipeBackClick = () => {
    setShowRecipeScreen(false);
  };

  // checking if timer is 0
  useEffect(() => {
    if (counter === 0 && !showResultsScreen) {
      const totalPoint = localStorage.getItem('bar_game_total_point') || 0;
      submitPoint(0, totalPoint);
      setResultStatus({ isTimeUp: true, isWinner: false });
      LoseSound.play();
      setShowResultsScreen(true);
    }
  }, [counter, showRecipeScreen]);

  const onStartClicked = () => {
    setIsZoomed(true);
    setShowAllotedRecipeScreen(true);
  };

  const onRecipesClicked = () => {
    const check = !isExtraSelected && !isBottleSelected && !isStirring;
    if (!check) return;
    setShowRecipeScreen(true);
  };

  const renderGameElements = () => (
    <>
      {isStirring && <Stirrer onStirComplete={onStirComplete} />}

      <CircularTimer text={counter} />

      <Glass />

      <GlassLevel
        currentColor={getCurrentGlassColor(currentSteps)}
        currentLevel={getCurrentGlassLevel(currentSteps)}
      />

      <RecipeNameBanner text={RECIPE_CONFIG[currentRecipeKey].title} />

      <ShelfItems
        enabled={!isBottleSelected && !isExtraSelected && !isStirring}
        onItemSelected={onBottleSelected}
      />

      <CancelSelectionButton
        enabled={(isExtraSelected || isBottleSelected) && !isActionOngoing}
        onClick={onCancelSelection}
      />

      {isBottleSelected && (
        <SelectedBottle
          isPouring={isPouring}
          imgSrc={selectedBottleUrl}
          onPouringComplete={onPouringComplete}
          data={selectedBottleData}
        />
      )}

      <RightControls
        onStirClicked={onStirClicked}
        dropOrPourText={isExtraSelected ? 'Drop' : 'Pour'}
        onDropOrPourClicked={onDropOrPourClicked}
        onResetClicked={onResetClicked}
      />

      <LeftControls onRecipeClicked={onRecipesClicked} />

      <Lemon
        enabled={!isExtraSelected && !isBottleSelected && !isStirring}
        onClick={() => onExtraSelected(LEMON_SELECTED_URL, 'lemon')}
      />

      <Leaf
        enabled={!isExtraSelected && !isBottleSelected && !isStirring}
        onClick={() => onExtraSelected(LEAF_SELECTED_URL, 'leaf')}
      />

      <LemonTwist
        enabled={!isExtraSelected && !isBottleSelected && !isStirring}
        onClick={() => onExtraSelected(LEMON_TWIST_URL, 'lemonTwist')}
      />

      <LemonWithLeaf
        enabled={!isExtraSelected && !isBottleSelected && !isStirring}
        onClick={() => onExtraSelected(LEMON_WITH_LEAF_URL, 'lemonWithLeaf')}
      />

      <Coconut
        enabled={!isExtraSelected && !isBottleSelected && !isStirring}
        onClick={() => onBottleSelected(coconutConfig)}
      />

      <DW12
        enabled={!isExtraSelected && !isBottleSelected && !isStirring}
        onClick={onBottleSelected}
      />

      <Soda
        enabled={!isExtraSelected && !isBottleSelected && !isStirring}
        onClick={onBottleSelected}
      />
      <Ale
        enabled={!isExtraSelected && !isBottleSelected && !isStirring}
        onClick={onBottleSelected}
      />

      {isExtraSelected && (
        <SelectedExtra
          isDropping={isDropping}
          onDroppingComplete={onDroppingComplete}
          imgSrc={selectedExtraUrl}
        />
      )}

      <StepProgressStatusContainer
        currentStatus={getCurrentStatus(
          RECIPE_CONFIG[currentRecipeKey].id,
          currentSteps,
        )}
        levels={RECIPE_CONFIG[currentRecipeKey].steps}
      />

      <BackButton onClick={() => setShowBackConfirmation(true)} />
    </>
  );

  const renderFirstScreenElements = () => (
    <>
      <FirstScreenControls
        onStartClicked={onStartClicked}
        onRecipesClicked={onRecipesClicked}
      />
      <FirstScreenBranding />
      <ScoreBoard />
    </>
  );

  const renderResultScreenElements = () => (
    <>
      <ResultScreen
        onReplaySelected={replayReset}
        onBackSelected={onBackClick}
        status={resultStatus}
      />
    </>
  );

  const renderRewardsScreen = () => (
    <>
      <RewardScreen
        onOkSelected={() => {
          setShowRewardScreen(false);
          onSubmit();
        }}
      />
    </>
  );

  useEffect(() => {
    const totalPoint = localStorage.getItem('bar_game_total_point');

    if (totalPoint && totalPoint >= 1000) {
      setShowRewardScreen(true);
    }
  }, []);

  const renderAllotedRecipeScreen = () => (
    <>
      <AllotedRecipeScreen
        recipe={RECIPE_CONFIG[currentRecipeKey].title}
        onOkClicked={() => setShowAllotedRecipeScreen(false)}
      />
    </>
  );

  return (
    <div className={styles.container}>
      <CSSTransition
        in={showRecipeScreen}
        timeout={200}
        classNames="fade-transition"
        unmountOnExit
      >
        <RecipeScreen onBackClicked={onRecipeBackClick} />
      </CSSTransition>

      <BgImage isZoomed={isZoomed} onLoaded={() => console.log('bg loaded')} />

      {isAssetsLoaded && <Loading />}
      {isZoomed && !isAssetsLoaded && renderGameElements()}
      {!isZoomed && !isAssetsLoaded && renderFirstScreenElements()}
      {showResultsScreen && renderResultScreenElements()}
      {showRewardScreen && renderRewardsScreen()}
      {showAllotedRecipeScreen && renderAllotedRecipeScreen()}
      <CSSTransition
        in={showBackConfirmation}
        timeout={200}
        classNames="fade-transition"
        unmountOnExit
      >
        <BackConfirmation
          onYesSelected={onBackClick}
          onNoSelected={() => setShowBackConfirmation(false)}
        />
      </CSSTransition>

      <Confetti active={resultStatus.isWinner} config={config} />
    </div>
  );
}

export default Main;
