import React from 'react';
import Ticket1 from './assets/images/Ticket1.png';
import Ticket2 from './assets/images/Ticket2.png';
import Ticket3 from './assets/images/Ticket3.png';
import Ticket4 from './assets/images/Ticket4.png';
import './assets/styles/BuyTickets.css';
import Box_Office from './assets/images/box_office.png'
import Strip from './assets/images/Strip.png';

export default props => {
    const [createGrid, setCreateGrid] = React.useState(false);
    React.useEffect(()=>{
		document.body.style.backgroundColor="#fe87a0";
		let f = document.getElementById("footer");
		f.style.backgroundColor="#fe87a0";
		props.addLeaves();
		// buy-ticket-active
		document.getElementById("buy_ticket").className="buy-ticket-active";
		document.getElementById("buy_ticket-link").style.color="white";
		document.getElementById("buy_ticket-mob").className="buy-ticket-active";
		document.getElementById("buy_ticket-link-mob").style.color="white";
        return (()=>{
			document.getElementById("buy_ticket").className="";
			document.getElementById("buy_ticket-link").style.color="black";
			document.getElementById("buy_ticket-mob").className="";
			document.getElementById("buy_ticket-link-mob").style.color="black";
			props.removeLeaves();
		})
    },[])
    React.useEffect(()=>{
		props.createGridFunc(createGrid, "BuyTicketContent", "firstItem",1,  2, 2, 2);
	},[createGrid])
    return (
        <div className="BuyTicketContent">
            <div className="BuyTicketsHead">
                <div className="BuyTicketHeadTitleBlck">
                    <div className="BTTitleBlck">
                        <h1 className="BTTitle">BOX OFFICE</h1>
                    </div>
                    <div className="BTTitleImgBlck">
                        <img className="BTTitleImg" src={Strip} />
                    </div>
                </div>
                <div className="BTHeadImgBlck">
                    <img className="SideImgBuyTicket" src={Box_Office} />
                </div>
            </div>
            <div  className="BuyTicketsContent" id="BuyTicketContent">
                <div onClick={() => {props.setShowOtherPage(true)}} id="firstItem" className="TicketBlck">
                <a href="https://nh7.in/bacardi-nh7-weekender-2020-dec5-2020.html" target="_blank"><img src={Ticket1} id="fireImg" className="TicketImg" /></a>
                {/* <a  target="_blank"><img src={Ticket1} id="fireImg" className="TicketImg" /></a> */}

                </div>
                
                <div onClick={() => {props.setShowOtherPage(true)}} className="TicketBlck">
                    {/* <img src={Ticket2} className="TicketImg" /> */}
                <a href="https://nh7.in/bacardi-nh7-weekender-2020-dec5-2020.html" target="_blank"><img src={Ticket2} className="TicketImg" /></a>
                {/* <a  target="_blank"><img src={Ticket2} className="TicketImg" /></a> */}


                </div>
				<div onClick={() => {props.setShowOtherPage(true)}} className="TicketBlck">
                    {/* <img src={Ticket2} className="TicketImg" /> */}
                <a href="https://nh7.in/bacardi-nh7-weekender-2020-dec5-2020.html" target="_blank"><img src={Ticket3} className="TicketImg" /></a>
                {/* <a  target="_blank"><img src={Ticket2} className="TicketImg" /></a> */}


                </div>
                <div  onClick={() => {props.setShowOtherPage(true)}} className="TicketBlck">
                    {/* <img src={Ticket3} className="TicketImg" /> */}
					<a href="https://nh7.in/casa-bacard-athome-experiences-dec5-2020.html" target="_blank"><img  src={Ticket4} onLoad={()=>setCreateGrid(true)} className="TicketImg" /></a>
					
                {/* <a target="_blank"><img  src={Ticket3} onLoad={()=>setCreateGrid(true)} className="TicketImg" /></a> */}

                </div>
            </div>
            
        </div>
    )
};

// import React from 'react';
// import Ticket2 from './assets/images/Ticket2.png'
// import Ticket3 from './assets/images/Ticket3.png'
// import './assets/styles/BuyTickets.css';
// import Ticket1 from './assets/images/Ticket1.png'
// import Box_Office from './assets/images/box_office.png'
// import Strip from './assets/images/Strip.png';
// export default props => {
//     const [createGrid, setCreateGrid] = React.useState(false);
//     React.useEffect(()=>{
// 		document.body.style.backgroundColor="#fe87a0";
// 		let f = document.getElementById("footer");
// 		f.style.backgroundColor="#fe87a0";
// 		props.addLeaves();
// 		// buy-ticket-active
// 		document.getElementById("buy_ticket").className="buy-ticket-active";
// 		document.getElementById("buy_ticket-link").style.color="white";
// 		document.getElementById("buy_ticket-mob").className="buy-ticket-active";
// 		document.getElementById("buy_ticket-link-mob").style.color="white";
//         return (()=>{
// 			document.getElementById("buy_ticket").className="";
// 			document.getElementById("buy_ticket-link").style.color="black";
// 			document.getElementById("buy_ticket-mob").className="";
// 			document.getElementById("buy_ticket-link-mob").style.color="black";
// 			props.removeLeaves();
// 		})
//     },[])
//     React.useEffect(()=>{
// 		// alert(`${window.innerWidth}`)
// 		props.createGridFunc(createGrid, "BuyTicketContent", "firstItem",1,  2, 2, 2)
// 		// if(createGrid){let grid = document.createElement('table');
// 		// let tableContainer = document.createElement('div');
// 		// tableContainer.id="tableContainer";
// 		// let root = document.getElementById('root');
// 		// let tbody = document.createElement('tbody');
// 		// let btc  = document.getElementById('BuyTicketContent');
// 		// let fi = document.getElementById('firstItem');
// 		// let bodyHeight = document.body.scrollHeight;
// 		// let fireImg = document.getElementById("fireImg");
//         // // for(let x = 0; x<parseInt(btc.scrollHeight/50-((fireImg.clientHeight/50)*0.5)); x++)
// 		// for(let x = 0; x<parseInt(fi.clientHeight/50); x++)
        
// 		// {
// 		// 	// console.log(`number of rows : ${parseInt(fi.scrollHeight/50)}`)
// 		// 	let tr = document.createElement('tr');
// 		// 	for(let y = 0; y<btc.clientWidth/50 - ((btc.clientWidth*0.1)/40); y++)
// 		// 	{
// 		// 		let td = document.createElement('td');
// 		// 		td.setAttribute('rowSpan', '1')
// 		// 		td.setAttribute('colSpan', '1');
// 		// 		td.setAttribute('height', '50px');
// 		// 		td.setAttribute('width', '50px');

// 		// 		tr.appendChild(td);
// 		// 	}
// 		// 	tbody.appendChild(tr);
// 		// }
// 		// grid.appendChild(tbody);
// 		// tableContainer.style.marginLeft=50*(parseInt((window.innerWidth/50)*0.08))+"px"
// 		// tableContainer.style.marginTop=fireImg.clientHeight*0.3+"px";
// 		// tableContainer.appendChild(grid);
// 		// btc.insertBefore(tableContainer, fi);
// 		// console.log(grid);
// 		// grid.style.alignSelf="right";}
// 	},[createGrid])
//     return (
//         <div className="BuyTicketContent">
//             <div className="BuyTicketsHead">
//                 <div className="BuyTicketHeadTitleBlck">
//                     <div className="BTTitleBlck">
//                         <h1 className="BTTitle">BOX OFFICE</h1>
//                     </div>
//                     <div className="BTTitleImgBlck">
//                         <img className="BTTitleImg" src={Strip} />
//                     </div>
//                 </div>
//                 <div className="BTHeadImgBlck">
//                     <img className="SideImgBuyTicket" src={Box_Office} />
//                 </div>
//             </div>
//             <div  className="BuyTicketsContent" id="BuyTicketContent">
//                 <div onClick={() => {props.setShowOtherPage(true)}} id="firstItem" className="TicketBlck">
//                 <a href="https://nh7.in/bacardi-nh7-weekender-2020-dec5-2020.html" target="_blank"><img src={Ticket1} id="fireImg" className="TicketImg" /></a>
//                 {/* <a  target="_blank"><img src={Ticket1} id="fireImg" className="TicketImg" /></a> */}

//                 </div>
                
//                 <div onClick={() => {props.setShowOtherPage(true)}} className="TicketBlck">
//                     {/* <img src={Ticket2} className="TicketImg" /> */}
//                 <a href="https://nh7.in/bacardi-nh7-weekender-2020-dec5-2020.html" target="_blank"><img src={Ticket2} className="TicketImg" /></a>
//                 {/* <a  target="_blank"><img src={Ticket2} className="TicketImg" /></a> */}


//                 </div>
//                 <div  onClick={() => {props.setShowOtherPage(true)}} className="TicketBlck">
//                     {/* <img src={Ticket3} className="TicketImg" /> */}
// 					<a href="https://nh7.in/bacardi-nh7-weekender-2020-dec5-2020.html" target="_blank"><img  src={Ticket3} onLoad={()=>setCreateGrid(true)} className="TicketImg" /></a>

//                 {/* <a target="_blank"><img  src={Ticket3} onLoad={()=>setCreateGrid(true)} className="TicketImg" /></a> */}

//                 </div>
//             </div>
            
//         </div>
//     )
// };