import React from 'react';
import styles from './styles.module.css';

function InitialMessage({ onOkClicked }) {
  return (
    <div className={styles.container}>
      <div className={styles['middle-container']}>
        <div className={styles['middle-container__points']}>
          SPLASH SOME COLORS ON A CANVAS OF YOUR CHOICE!
        </div>

        <div className={styles['middle-container__feedback']}>
          Submit your best artwork and stand a chance to win a Breezer festival
          kit.
        </div>

        <div className={styles['actions-container']}>
          <div
            onClick={onOkClicked}
            className={styles['action-container__button']}
          >
            START GAME
          </div>
        </div>
      </div>
    </div>
  );
}

export default InitialMessage;
