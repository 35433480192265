import React from 'react';
import styles from './styles.module.css';

function LeftControls({ onRecipeClicked }) {
  return (
    <div className={styles.container}>
      <div onClick={onRecipeClicked} className={styles['action-button']}>
        <img
          className={styles['recipes-button-image']}
          alt="left-recipe-btn"
          src="/sv-gaming/dewars/recipe-btn.png"
        />
      </div>
    </div>
  );
}

export default LeftControls;
