import React from 'react';
import Board from './Board';
import Card  from './Card';
import  './assets/styles/Drag.css';
import A1 from './assets/Audio/A1.mp3';
import {Image} from 'react-bootstrap';
import close_modal from './assets/images/modal-close.png';

import Aos from "aos";
import "aos/dist/aos.css";


const Data = require('./CardBoardData');
const setColor = (color, trgts) => {
  console.log(`making ${JSON.stringify(trgts)} ${color}`)
  for(let i in trgts)
    if(document.getElementById(trgts[i]))
      document.getElementById(trgts[i]).style.background=color;
}

const CardData = props => props.data.map(item => 
    <Card key={item.key} reset={props.reset} mobView={props.mobView} setActiveCard={props.setActiveCard} activeCard={props.activeCard} setColor={setColor} targetBoards={item.targetBoards} id={item.id} name={item.cardName} className={item.className} draggable={true} />
  )
const BoardData = props =>  props.data.map(item => 
    <Board key={item.key} mobView={props.mobView} setActiveCard={props.setActiveCard} activeCard={props.activeCard} setColor={setColor} targetCards={item.pairCards} id={item.id} name={item.boardName} className={item.className} />
  )



function Drag() {
  React.useEffect(() => {
    Aos.init({duration:2000});
    if(window.innerWidth<768)
      setMobView(true);
    // alert(`window.innerHeight : ${window.innerHeight} window.innerWidth : ${window.innerWidth} ratio : ${window.innerHeight/window.innerWidth}`);

}, [])
  const [activeCard, setActiveCard] = React.useState('');
  const [mobView, setMobView] = React.useState(false);
  const reset = () => {
    // for(let i in Data.CardData)
    //   {
    //     console.log(`${Data.CardData[i].id}`)
    //     if(Data.CardData[i].id === prv)
    //     {
    //       setColor("aquamarine", Data.CardData[i].targetBoards)
    //     }
    //   }
    //   setColor("orange", [prv]);
  }
  // const prevState = React.useRef();
  const [prv, stePrv] = React.useState('');
  React.useEffect(()=>{
    // console.log(`prv : ${prv}`);
    if(!activeCard&&prv)
    {
      console.log(`THE FIRST IF CASE`)
      setColor("orange", [prv])
      for(let i in Data.CardData)
      {
        console.log(`${Data.CardData[i].id}`)
        if(Data.CardData[i].id === prv)
        {
          setColor("aquamarine", Data.CardData[i].targetBoards)
        }
      }
      
      // alert(`YOYO`);
    }
    else{
      // setColor("orange", [prv]);

      if(prv)
      {
        console.log(`THE SECOND ELSE CASE and prv : ${prv}`)
        for(let i in Data.CardData)
        {
          console.log(`${Data.CardData[i].id}`)
          if(Data.CardData[i].id === prv)
          {
            setColor("aquamarine", Data.CardData[i].targetBoards)
          }
          
        }
        for(let i in Data.CardData)
        {
          if(Data.CardData[i].id === activeCard)
          {
            setColor("green", Data.CardData[i].targetBoards)
          }
        }
        if(prv!==activeCard)
          setColor("orange", [prv]);
      }
      stePrv(activeCard);
    }
  },[activeCard]);
  return (
    <div id="non-drop-area"   data-aos="fade-up" className="App">
      <div className="music-player">
      <h1>JUKE-BOX</h1>
        <div className="music-title">
        <h5 className="bounce">DRAG ONE OF THESE</h5>
        <h5 className="bounce">ONTO ONE OF THESE</h5>
        </div>
        <div id="audio" className="audio-player">
          <audio controls controlsList id="audio-element" src={A1} ></audio>
        </div>
       
      </div>
    
      <div className="flexbox">
        <div id="board-a" className="cards">
          <CardData reset={reset} mobView={mobView} setActiveCard = {setActiveCard} activeCard={activeCard} data={Data.CardData} />
        </div>
        <div id="board-b" className="board456">
          <BoardData mobView={mobView} data={Data.BoardData} setActiveCard = {setActiveCard} activeCard={activeCard} />
        </div>
      </div>
      
    </div>
  );
}

export default Drag;
