import React, { useState } from 'react';
import WithBackgroundImage from '../../components/WithBackgroundImage/WithBackgroundImage';
import ColoringAndControls from '../ColoringAndContorls/ColoringAndControls';
import StartScreen from '../StartScreen/StartScreen';
import convertDataToUri from './convertDataToUri';

const saveSvgAsPng = require('save-svg-as-png');

function GameScreen({ onSubmit }) {
  const [showFirstScreen, setShowFirstScreen] = useState(true);

  const getImageDataBlob = async () => {
    const imageUri = await saveSvgAsPng.svgAsPngUri(
      document.getElementById('mysvg'),
    );
    onSubmit(convertDataToUri(imageUri));
  };

  return (
    <WithBackgroundImage>
      {showFirstScreen ? (
        <StartScreen onClick={() => setShowFirstScreen(false)} />
      ) : (
        <ColoringAndControls onSubmit={getImageDataBlob} />
      )}
    </WithBackgroundImage>
  );
}

export default GameScreen;
