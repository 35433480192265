import React from 'react';
import styles from './styles.module.css';

function ColorSelector({
  color1,
  color2,
  color3,
  color4,
  color5,
  onItemClick,
}) {
  return (
    <>
      <div className={styles['color-item']} onClick={() => onItemClick(color1)}>
        <img
          className={styles['color-item__img']}
          alt="color"
          src="/sv-gaming/colorfy/images/colors/blue-color.png"
        />
        <div
          className={styles['color-item__tag']}
          style={{ backgroundColor: '#335AA9' }}
          onClick={() => onItemClick(color2)}
        >
          <div className={styles['rotated-text']}> Blueberry</div>
        </div>
      </div>

      <div className={styles['color-item']} onClick={() => onItemClick(color2)}>
        <img
          className={styles['color-item__img']}
          alt="color"
          src="/sv-gaming/colorfy/images/colors/yellow-color.png"
        />
        <div
          className={styles['color-item__tag']}
          style={{ backgroundColor: '#FFCA05' }}
          onClick={() => onItemClick(color2)}
        >
          <div className={styles['rotated-text']}> Jamaican Passion</div>
        </div>
      </div>

      <div className={styles['color-item']} onClick={() => onItemClick(color3)}>
        <img
          className={styles['color-item__img']}
          alt="color"
          src="/sv-gaming/colorfy/images/colors/purple-color.png"
        />
        <div
          className={styles['color-item__tag']}
          style={{ backgroundColor: '#8063BC' }}
          onClick={() => onItemClick(color2)}
        >
          <div className={styles['rotated-text']}> Blackberry Crush</div>
        </div>
      </div>

      <div className={styles['color-item']} onClick={() => onItemClick(color4)}>
        <img
          className={styles['color-item__img']}
          alt="color"
          src="/sv-gaming/colorfy/images/colors/pink-color.png"
        />
        <div
          className={styles['color-item__tag']}
          style={{ backgroundColor: '#E2366C' }}
          onClick={() => onItemClick(color2)}
        >
          <div className={styles['rotated-text']}> Cranberry</div>
        </div>
      </div>

      <div className={styles['color-item']} onClick={() => onItemClick(color5)}>
        <img
          className={styles['color-item__img']}
          alt="color"
          src="/sv-gaming/colorfy/images/colors/orange-color.png"
        />
        <div
          className={styles['color-item__tag']}
          style={{ backgroundColor: '#F05A0F' }}
          onClick={() => onItemClick(color2)}
        >
          <div className={styles['rotated-text']}> Orange</div>
        </div>
      </div>
    </>
  );
}

export default ColorSelector;
