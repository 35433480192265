import React from 'react';
import styles from './styles.module.css';

function WithBackgroundImage(props) {
  const { children = false } = props;

  return (
    <div className={styles.container}>
      <img
        className={styles['bg-image']}
        src="/sv-gaming/colorfy/images/bg-main.png"
        alt="bg-main"
      />
      <div className={styles.children}>{children}</div>
    </div>
  );
}

export default WithBackgroundImage;
