/* eslint-disable react/destructuring-assignment */
import React from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import styles from './styles.module.css';

const SaturationSlider = ({
  currentSatLevel = 100,
  currentColor,
  onChange = (e) => console.log('on after change', e),
}) => {
  return (
    <div className={styles.container}>
      <div className={styles['shade-details-container']}>
        <div className={styles['shade-details-container__text']}>
          Selected Color
        </div>
        <div
          className={styles['shade-details-container__color']}
          style={{ backgroundColor: currentColor }}
        />
      </div>
      <div className={styles['slider-container']}>
        <Slider
          trackStyle={{ backgroundColor: currentColor }}
          handleStyle={{
            borderColor: 'white',
            height: '4.5vh',
            width: '4.5vh',
            marginLeft: '-1.2vh',
            marginTop: 8,
            backgroundColor: currentColor,
          }}
          railStyle={{ backgroundColor: currentColor, width: '2vh' }}
          defaultValue={currentSatLevel}
          value={currentSatLevel}
          vertical
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default SaturationSlider;
