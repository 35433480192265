const sounds = [
  '/sv-gaming/dewars/assets/water-pouring.mp3',
  '/sv-gaming/dewars/assets/background.wav',
  '/sv-gaming/dewars/assets/beep.wav',
  '/sv-gaming/dewars/assets/Bacardi_Win.wav',
  '/sv-gaming/dewars/assets/Bacardi_Lose.wav',
  '/sv-gaming/dewars/assets/1000-points.wav',
];

const SoundLoader = async () => {
  try {
    const promises = await sounds.map((src) => {
      return new Promise((resolve, reject) => {
        // eslint-disable-next-line no-undef
        const audio = new Audio();
        audio.src = src;
        audio.onload = resolve;
        audio.onerror = reject;
      });
    });
    await Promise.all(promises);
  } catch (error) {
    // eslint-disable-next-line no-alert
    alert('failed to load assets', error);
  }
};

export default SoundLoader;
