import React from 'react';
import styles from './styles.module.css';

function BgImage({ isZoomed = false, onLoaded }) {
  const getClasses = () =>
    isZoomed
      ? `${styles['bg-image']} ${styles['bg-image--zoomed']}`
      : `${styles['bg-image']}`;

  return (
    <img
      alt="bg-logo"
      className={getClasses()}
      src="/sv-gaming/dewars/dewars-backdrop.png"
      onLoad={onLoaded}
    />
  );
}

export default BgImage;
