import React, { useEffect } from 'react';
import './App.css';

import PhotoBooth from './PhotoBooth.js';

import FullPage from './FullPage.js';
import { BrowserRouter as Router} from 'react-router-dom'; 
function App() {
  useEffect(()=>{
    document.title="Bacardi NH7 WEEKENDER";
    window.onload=function()
    {
    }
  },[])
  return (
    <Router>
	  {/* <PhotoBooth /> */}
    <FullPage />
    </Router>
  );
}

export default App;
