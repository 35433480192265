import React from 'react';
import styles from './styles.module.css';

function FirstScreenBranding() {
  // eslint-disable-next-line react/jsx-curly-brace-presence
  return (
    <img
      src="./sv-gaming/bacardi/assets/images-bacardi/logo-image.png"
      alt="brand-logo"
      className={styles['logo-image']}
    />
  );
}

export default FirstScreenBranding;
