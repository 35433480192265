import { isMobile, isAndroid } from 'react-device-detect';
import S3 from 'react-aws-s3';
import swal from '@sweetalert/with-react';
import Axios from 'axios';

const config = {
  bucketName: 'colorfy-submissions',
  region: 'us-east-1',
  accessKeyId: 'AKIASIJZN5SRFAE3K4WG',
  secretAccessKey: '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',
  s3Url: 'https://colorfy-submissions.s3.amazonaws.com',
};

const ReactS3Client = new S3(config);

function validateEmail(email) {
  // eslint-disable-next-line max-len
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const uploadTos3 = async (imageDataBlog) => {
  const fileName = `${Math.floor(Math.random() * 100000000)}-submission`;

  // eslint-disable-next-line no-undef
  const image = new File([imageDataBlog], fileName, { type: 'image/png' });

  const data = await ReactS3Client.uploadFile(image, fileName);
  return data.location;
};

const handleSubmission = async (imageDataBlob) => {
  try {
    let name = null;

    do {
      // eslint-disable-next-line no-await-in-loop
      name = await swal({
        text: 'Name',
        content: {
          element: 'input',
          attributes: {
            placeholder: 'Enter Full Name',
          },
        },
        button: {
          text: 'Next',
          closeModal: false,
        },
      });

      if (!name) {
        // eslint-disable-next-line no-await-in-loop
        await swal({
          title: 'Error',
          text: 'Invalid Name',
          icon: 'error',
        });
      }
    } while (!name);

    let email = null;

    do {
      // eslint-disable-next-line no-await-in-loop
      email = await swal({
        text: 'email',
        content: {
          element: 'input',
          attributes: {
            placeholder: 'example@domain.com',
          },
        },
        button: {
          text: 'Submit',
          closeModal: false,
        },
      });

      if (!email || !validateEmail(email)) {
        // eslint-disable-next-line no-await-in-loop
        await swal({
          title: 'Error',
          text: 'Invalid Email',
          icon: 'error',
        });
      }
    } while (!email || !validateEmail(email));

    swal({
      title: 'Submitting',
      text: `please wait...`,
      icon: 'info',
      button: {
        visible: false,
      },
      closeOnClickOutside: false,
      closeOnEsc: false,
    });

    const url = await uploadTos3(imageDataBlob);

    await Axios.post('https://submissions.sportvot.com/colorfy-sheets', {
      email,
      link: url,
      name,
    });

    swal({
      title: 'Success',
      // eslint-disable-next-line max-len
      text: `We will contact you on ${email} if you are one of our lucky winners to win the BREEZER - festival kit`,
      icon: 'success',
    });
  } catch (e) {
    swal({
      title: 'Error',
      text: 'Oops! Something went wrong.',
      icon: 'error',
    });
    swal.stopLoading();
    swal.close();
  }
};

const beginSubmissionFlow = (imageDataBlob, fullScreenHandle) => {
  if (isMobile && isAndroid) {
    fullScreenHandle.exit();
  }
  handleSubmission(imageDataBlob);
};

export default beginSubmissionFlow;
