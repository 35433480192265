import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import './assets/styles/Home.css';
import loadingVideo from './assets/finalRenders/mp4_1920_1080.mp4'
import Modal from 'react-modal'
import Mobile_loadingVideo from './assets/finalRenders/MP4_1080_1920_1.mp4'
import Drag from './Drag.js'
import './assets/styles/LoadingPage.css';
import ScoreBoard from './assets/images/tips.png'
// import PlaceHolder from './assets/images/map_pin.png';
// import ReactDOM from 'react-dom';
// import loadingGif from './assets/images/Desktop_LandingPage.gif'
import { useAccordionToggle,Image,Button } from 'react-bootstrap';
import LoadingPage from './LoadingPage.js';

import close_modal from './assets/images/modal-close.png';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link
  } from "react-router-dom";
  Modal.setAppElement("#root")
  function removeLoader(){
      let loader = document.getElementById("loader");
      if(loader)
      document.getElementById("root").removeChild(loader);
  }
  function addLoader() {
    //   alert(`called`)
    let loader = document.createElement('div');
    loader.id="loader";
    loader.style.backgroundColor="#87ceeb";
    loader.style.width = window.innerWidth+"px";
    loader.style.height = window.innerHeight+"px";
    loader.style.position = "fixed";
    loader.style.zIndex = 12;
    document.getElementById("root").prepend(loader);
    ReactDOM.render(
        
          <LoadingPage />
        ,
        document.getElementById('loader')
      );
    // loader.innerHTML = '<div><div class="container-fluid"><div class="loadingDiv row"><div class="col"><h1 class="loadingText">BACARDI</h1><h1 class="loadingText">NH7 WEEKENDER</h1><h6 class="loadingSubText">THE HAPPIEST MUSIC FESTIVAL<br>COMES HOME TO YOU!</h6></div></div></div></div>';
    // ReactDOM.render(LoadingPage, loader);
    // loader.appendChild(  );
    // React.createElement(LoadingPage)
    // document.getElementById("root").appendChild(loader);
    

  }
export default props => {
    const [videoEnded, setVideoEnded] = useState(false);
    const [vidWidth, setVidWidth] = useState(0);
    const [playVid, setPlayVid] = useState(true);
    const [foodComingSoon, setFoodComingSoon] = useState(false);
    const [loadMobileVid, setLoadMobileVid] = useState(false);
    const [totalScore, setTotalScore] = useState(0);
    const [isModalOpen,setModalOpen] = useState(false);
    const [loadingCompleted, setLoadingCompleted] = useState(false);
    const [displayScoreBoard,setDisplayScoreBoard] = useState(false);
    React.useEffect(()=>{
        if(loadingCompleted){
            let vidBx = document.getElementById("vidBx");
        let f = document.getElementById("footer");
        f.style.backgroundColor="#6ba352";
        document.body.style.backgroundColor = '#6ba352';
        // vidBx.style.backgroundSize=`${window.innerWidth}px ${window.innerHeight}px`;
        let navbar = document.getElementById("navbar-custom");

        if(vidBx&&navbar){
            vidBx.style.height=window.innerHeight+"px";
            vidBx.style.width=window.innerWidth+"px";
            navbar.style.position = "absolute";
            navbar.parentElement.style.marginTop="0px";
            navbar.parentElement.style.marginBottom="0px";

            // navbar.style.marginTop = "0px";
            if(sessionStorage.getItem('videoPlayed')==="true")
                setPlayVid(false);
            else
                sessionStorage.setItem('videoPlayed', true);
                // document.cookie="VideoPlayed=true";
            // window.onbeforeunload = function(e) {
            //     alert(`yo`)
            //     document.cookie = 'VideoPlayed=false';
            // };

            if(window.innerWidth<769)
            {
                setLoadMobileVid(true);
                // alert(`setting loadMobileVid to true`)
            }
        }
        

        
        }
    },[loadingCompleted])
    React.useEffect(()=>{
        setLoadingCompleted(true);
        if(!sessionStorage.getItem('videoPlayed'))
            addLoader();
        let s1 =  parseInt(localStorage.getItem('dewars_total_point'));
        let s2 = parseInt(localStorage.getItem('bacardi_total_point'))
        if(isNaN(s1)||isNaN(s2))
        {
            if(isNaN(s1))
            setTotalScore(s2);
            else
            setTotalScore(s1);
        }
        else
        setTotalScore(s1+s2);

        return (()=>{
            window.onbeforeunload = function(e) {
                document.cookie = 'VideoPlayed=false';
            };
            let navbar = document.getElementById("navbar-custom");
            if(navbar)
            {
                navbar.parentElement.style.marginTop="3%";
                navbar.parentElement.style.marginBottom="3%";
            }
            
        })
        
    },[]);
    React.useEffect(()=>{
        // let animInterval = undefined;
        if(videoEnded)
        {
            let navbar = document.getElementById("navbar-custom");
            navbar.style.position = "absolute";
            // navbar.style.marginTop = "0px";
            navbar.parentElement.style.marginTop="0px";
        }
        return()=>{
            let navbar = document.getElementById("navbar-custom");
            if(navbar)
            navbar.style.position = "relative";
            
        }
    },[videoEnded]);

    React.useEffect(()=>{

        if(loadingCompleted) {
            let e =document.getElementById('score-board');
            // console.log(displayScoreBoard)
            if(displayScoreBoard){
              
                e.style.display = "block"
                e.style.animation = "displayScore 0.5s ease-in";
                e.style.right = 0 + "px"
            }
            else if(e.style.display == "block"){
                e.style.animation = "displayScoreRev 0.5s ease-out"
                e.style.right = -500 + "px"
            }
        }
      
        
    },[displayScoreBoard]);



    return (
        
        <div  id="content" className="homeParent">
            <div className="box1" id="vidBx" onMouseEnter={()=>setFoodComingSoon(true)} onMouseLeave={()=>setFoodComingSoon(false)}>
            {/* <a onMouseEnter={()=>setFoodComingSoon(false)} onMouseLeave={()=>setFoodComingSoon(true)} target="_blank"><div className="ticketOverlay"></div></a> */}
            <a href="https://nh7.in/bacardi-nh7-weekender-2020-dec5-2020.html" onMouseEnter={()=>setFoodComingSoon(false)} onMouseLeave={()=>setFoodComingSoon(true)} target="_blank"><div className="ticketOverlay"></div></a>
            <a href="https://golouder.in/collections/bacardi-nh7-weekender-happyathome" target="_blank" >
                <div id="store_overLay_desktop"></div>
            </a>
            <a href="https://open.spotify.com/user/nkb56llfijr1xu5t4s6mykfuj/playlist/0i4LisdzL9GUGe6VPaWY4U?si=ag46uD23RS2YJDfD4S5L-g
            " target="_blank" >
                <div id="music_overLay_desktop"></div>
            </a>
            <a href="https://social.dotpe.in/order" target="_blank" >
                <div id="food_overLay_desktop"></div>
            </a>



            <a href="https://insider.in/live/6ec8e1eb-5088-4c82-a785-b3ac72c2b077/play?embed=true" target="_blank" >
                <div id="breezer_overLay_desktop"></div>
            </a>
            <a href="/game-breezer" target="_blank" >
                <div id="breezer_bar_overLay_desktop"></div>
            </a>
            <a href="https://insider.in/live/6ec8e1eb-5088-4c82-a785-b3ac72c2b077/play?embed=true" target="_blank" >
                <div id="dewar_overLay_desktop"></div>
            </a>
            <a href="/game-dewars" target="_blank" >
                <div id="dewar_bar_overLay_desktop"></div>
            </a>
            <a href="https://insider.in/live/6ec8e1eb-5088-4c82-a785-b3ac72c2b077/play?embed=true" target="_blank" >
                <div id="barcardi_overLay_desktop"></div>
            </a>
            <a href="/game-bacardi" target="_blank" >
                <div id="barcardi_bar_overLay_desktop"></div>
            </a>
             
                
                 <Link to="/photo-booth" >
                 <div id="photo_overLay_desktop">
                 </div>
                 </Link>
                
            
            {/* <div className="OtherOverlay"> */}
            <Link to="/photo-booth" >
            {/* <div className="foodOverLay">
            
                {
                    foodComingSoon?
                    <span>Coming Soon</span>
                    :
                    null
                }
            </div> */}
            </Link>
            {/* <img id="ticketsPin" src={PlaceHolder} style={{position:'absolute'}} height={50} width={50} /> */}

            {/* </div> */}
            
                {!videoEnded&&playVid?<video className='videoTag' id="vid" onPlay={()=>{ removeLoader(); let w = document.getElementById("vid").clientWidth;setVidWidth(w)}} width={window.innerWidth} height={window.innerHeight} onEnded={() => {setVideoEnded(true)}}  autoPlay muted>
                    <source src={window.innerWidth<769?Mobile_loadingVideo:loadingVideo} type='video/mp4' />
                </video>:null}
            </div>
            {/*<span id="musicPlayerModal" onClick={()=>setModalOpen(true)} className="to-juke-box">    
            </span>*/}
            <Modal isOpen={isModalOpen} onRequestClose={()=>{setModalOpen(false)}}>
                <Drag />
                <div className="modal-close-btn">
                    <Image src={close_modal} onClick={()=>setModalOpen(false)} fluid/>
                </div>
            </Modal>
            {!videoEnded&&playVid?null:
                 <div id="floatingBtn" className='floatingIcon'>
                 <div className="tipicon">
                  <Image  src={ScoreBoard} onClick={()=>setDisplayScoreBoard(true)} fluid/>
                 </div>
                 <div id="score-board" className="score-board">
                        <span className="score-board-title">NH7.in TIPS</span>
                     <br></br>
                     <ul>
                    <li> {'\u25CF'} Click on the stages to stream the performances</li>
                    <li> {'\u25CF'}  Click on the bars to play interactive games.</li>
                    <li> {'\u25CF'} Collect 1000 points at the Bacardí / Dewar's bar to stand a chance to win a Bacardí/Dewar's kit.</li>
                    <li> {'\u25CF'} Submit your best artwork at the Breezer bar and stand a chance to win a Breezer kit.</li> 
                    <li> {'\u25CF'} Order sumptuous meals from Social Offline at the FOOD section.</li>
                    <li> {'\u25CF'} The 'STORE' section has some great tees, mugs and badges for you on Louder.in</li> 
                    <li> {'\u25CF'} Click a picture at the 'PHOTO BOOTH' and mark your attendance at the festival with  #BacardiNH7 #DoWhatMovesYou #HappyAtHome</li>
                    <li> {'\u25CF'} Check out our curated festival playlist at the 'MASHUP STATION'!</li>
                     </ul>
                     <br></br>
                        {/* <span className="score-board-text">{totalScore}</span>
                     <br></br> */}
                     <Button className="bg-dark" onClick={()=>setDisplayScoreBoard(false)}>CLOSE</Button>
                 </div>
                 </div>
            }
           
           
        </div>
    )
};
