import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';

const DROPPING_TIME_MS = 1600;

function SelectedExtra({ isDropping = true, imgSrc, onDroppingComplete }) {
  const [isDroppingComplete, setIsDroppingComplete] = useState(false);
  const [isDroppingStarted, setIsDroppingStarted] = useState(false);

  useEffect(() => {
    if (isDropping && !isDroppingStarted && !isDroppingComplete) {
      setIsDroppingStarted(true);
    }
  }, [isDropping, isDroppingStarted, isDroppingComplete]);

  useEffect(() => {
    if (isDroppingStarted) {
      const interval = setInterval(() => {
        setIsDroppingComplete(true);
        onDroppingComplete();
      }, DROPPING_TIME_MS);

      return () => clearInterval(interval);
    }
  }, [isDroppingStarted]);

  const getClassNames = () => {
    if (isDroppingStarted) {
      return `${styles['image-container']}  ${styles['image-container--dropping']}`;
    }
    return `${styles['image-container']}  ${styles['image-container--floating']}`;
  };

  return (
    <div className={getClassNames()}>
      <img className={styles.image} alt="extra" src={imgSrc} />
    </div>
  );
}

export default SelectedExtra;
