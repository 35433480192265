import React, { useState, useEffect } from 'react';
import styles from './styles.module.css';

function ScoreBoard() {
  const [points, setPoints] = useState(
    localStorage.getItem('bar_game_total_point') || '0',
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setPoints(localStorage.getItem('bar_game_total_point') || '0');
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={styles.container}>
      <img
        style={{ width: '100%', height: '100%' }}
        alt="left-recipe-btn"
        src="/sv-gaming/bacardi/assets/images-bacardi/btn-container.png"
      />
      <p className={styles['btn-text']}>TOTAL POINTS {points}</p>
    </div>
  );
}

export default ScoreBoard;
