import React from 'react';
import styles from './styles.module.css';

function CircularTimer({ text = '0' }) {
  return (
    <div className={styles.backBtn}>
      <div className={styles['inner-back-btn']}>
        <p className={styles.backBtnText}>{text}</p>
      </div>
    </div>
  );
}

export default CircularTimer;
