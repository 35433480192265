import React from 'react';
import PinchToZoom from 'react-pinch-and-zoom';
import { isMobile } from 'react-device-detect';
import styles from './styles.module.css';
import CustomImage1 from '../CustomImage/CustomImage1';
import CustomImage2 from '../CustomImage/CustomImage2';
import CustomImage3 from '../CustomImage/CustomImage3';
import CustomImage4 from '../CustomImage/CustomImage4';
import CustomImage5 from '../CustomImage/CustomImage5';
import CustomImage6 from '../CustomImage/CustomImage6';

function ColoringScreen({
  onChangeClicked = console.log('change clicked'),
  name = 'Canvas 1',
  onFillColor,
  onReset,
  fillColors,
  currentSvgIndex = 0,
}) {
  const renderImage = () => {
    switch (currentSvgIndex) {
      case 0:
        return <CustomImage1 onFill={onFillColor} fillColors={fillColors} />;
      case 1:
        return <CustomImage2 onFill={onFillColor} fillColors={fillColors} />;
      case 2:
        return <CustomImage3 onFill={onFillColor} fillColors={fillColors} />;
      case 3:
        return <CustomImage4 onFill={onFillColor} fillColors={fillColors} />;
      case 4:
        return <CustomImage5 onFill={onFillColor} fillColors={fillColors} />;
      case 5:
        return <CustomImage6 onFill={onFillColor} fillColors={fillColors} />;

      default:
        return <></>;
    }
  };

  const renderImageWithZoom = () => <PinchToZoom>{renderImage()}</PinchToZoom>;

  return (
    <div className={styles.container}>
      <div className={styles['name-container']}>
        <div className={styles['name-container__text']}>{name}</div>
        <div
          onClick={onChangeClicked}
          className={styles['name-container__change-button']}
        >
          Change
        </div>
      </div>

      <div className={styles['coloring-container']}>
        <div className={styles['svg-container']} id="drawing_canvas_div">
          {isMobile ? renderImageWithZoom() : renderImage()}
          <div onClick={onReset} className={styles['refresh-button']}>
            Refresh
          </div>
        </div>
      </div>
    </div>
  );
}

export default ColoringScreen;
