export default (createGrid, gridOnElem, firstChild, alignRight, offsetAmt, offsetAmtTop) =>
{
	if(createGrid){let grid = document.createElement('table');
		let tableContainer = document.createElement('div');
		tableContainer.id="tableContainer";
		let root = document.getElementById('root');
		let tbody = document.createElement('tbody');
		
		let CuE  = document.getElementById(gridOnElem);
		// if(renderForPhone) CuE = document.getElementById("content-cu");

		let fi = document.getElementById(firstChild);
		// if(renderForPhone) fi = document.getElementById("first-contentForMob");

		let bodyHeight = document.body.scrollHeight;
		// let fireImg = document.getElementById("fireImg");
        // for(let x = 0; x<parseInt(CuE.scrollHeight/50-((fireImg.clientHeight/50)*0.5)); x++)
		for(let x = 0; x<parseInt(CuE.clientHeight/50)-offsetAmtTop; x++)
        
		{
			// console.log(`number of rows : ${parseInt(fi.scrollHeight/50)}`)
			let tr = document.createElement('tr');
			for(let y = 0; y<CuE.clientWidth/50-offsetAmt; y++)
			{
				let td = document.createElement('td');
				td.setAttribute('rowSpan', '1')
				td.setAttribute('colSpan', '1');
				td.setAttribute('height', '50px');
				td.setAttribute('width', '50px');

				tr.appendChild(td);
			}
			tbody.appendChild(tr);
		}
		grid.appendChild(tbody);
		tableContainer.style.marginLeft=50*offsetAmt+"px"
		tableContainer.style.marginTop=50*offsetAmtTop+"px"
		tableContainer.style.opacity="0.2";
		// tableContainer.style.marginLeft=50*(parseInt((window.innerWidth/50)*0.08))+"px"
		// tableContainer.style.marginTop=fireImg.clientHeight*0.3+"px";
		tableContainer.appendChild(grid);
		CuE.insertBefore(tableContainer, fi);
		console.log(grid);
		
		// if(alignRight)
			grid.style.alignSelf="right";
		}
}