const COLOR_CONFIGS = {
  YELLOW: {
    h: 47,
    s: 100,
    l: 51,
  },
  ORANGE: {
    h: 20,
    s: 88,
    l: 50,
  },
  RED: {
    h: 341,
    s: 75,
    l: 55,
  },
  BLUE: {
    h: 220,
    s: 54,
    l: 43,
  },
  PURPLE: {
    h: 260,
    s: 40,
    l: 56,
  },
};

export default COLOR_CONFIGS;
