import React, { useEffect, useState } from 'react';
import { Howl } from 'howler';
import styles from './styles.module.css';

const WAVE_FALL_TIME = 1300;
const BOTTLE_TURN_TIME = 1500;

function SelectedBottle({ isPouring, imgSrc, onPouringComplete, data }) {
  const [isPouringComplete, setIsPouringComplete] = useState(false);
  const [isPouringStarted, setIsPouringStarted] = useState(false);
  const [isWaveFallStarted, setIsWaveFallStarted] = useState(false);

  // sound

  const sound = new Howl({
    src: ['/sv-gaming/dewars/assets/water-pouring.mp3'],
    volume: 0.8,
  });

  useEffect(() => {
    if (isPouring && !isPouringStarted && !isPouringComplete) {
      setIsPouringStarted(true);
    }
  }, [isPouring, isPouringStarted, isPouringComplete]);

  useEffect(() => {
    if (isPouringStarted) {
      const interval1 = setInterval(() => {
        setIsPouringComplete(true);
        onPouringComplete();
        sound.stop();
      }, WAVE_FALL_TIME + BOTTLE_TURN_TIME);

      const interval2 = setInterval(() => {
        sound.load();
        setIsWaveFallStarted(true);
        sound.play();
      }, BOTTLE_TURN_TIME);

      return () => {
        clearInterval(interval1);
        clearInterval(interval2);
      };
    }
  }, [isPouringStarted]);

  const getBottleClassNames = () => {
    if (isPouringStarted) {
      return `${styles['image-container']}  ${styles['image-container--rotated']}`;
    }
    return `${styles['image-container']}  ${styles['image-container--floating']}`;
  };

  const getWaveClassNames = () => {
    if (!isWaveFallStarted) {
      return `${styles.wave}`;
    }
    return `${styles.wave}  ${styles['wave--falling']}`;
  };

  return (
    <>
      <div className={getBottleClassNames()}>
        <img className={styles.image} alt="bottle" src={imgSrc} />
      </div>

      <div className={styles['wave-container']}>
        <svg
          className={getWaveClassNames()}
          viewBox="0 0 297 1310"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M236.835 1309.78C231.447 1302.9 225.983 1296.02 220.521 1289.14L220.521 1289.14C206.129 1271.02 191.75 1252.92 178.797 1234.82C120.005 1152.65 90.6087 1070.48 112.656 988.31C126.758 935.75 161.909 882.6 196.951 829.614C216.692 799.766 236.398 769.97 252.287 740.361C296.381 658.192 311.079 576.022 281.683 493.853C269.187 458.925 248.724 423.997 228.196 388.957L228.196 388.957C200.432 341.566 172.549 293.973 164.099 245.904C149.401 163.735 193.495 81.5657 215.542 41.2019L237.27 0.000111699L132.253 -5.78096e-06L110.206 41.8054C88.159 82.1693 44.065 164.338 58.763 246.508C67.2131 294.577 95.0958 342.17 122.86 389.56C143.388 424.599 163.851 459.528 176.347 494.457C205.743 576.626 191.045 658.795 146.951 740.964C131.062 770.573 111.356 800.369 91.616 830.217L91.6157 830.218C56.5736 883.203 21.4225 936.353 7.32 988.913C-14.727 1071.08 14.669 1153.25 73.461 1235.42C86.4138 1253.52 100.793 1271.63 115.185 1289.75L115.185 1289.75L115.185 1289.75C120.488 1296.42 125.792 1303.1 131.027 1309.78L236.835 1309.78Z"
            fill={data.color}
          />
        </svg>
      </div>
    </>
  );
}

export default SelectedBottle;
