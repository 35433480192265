export default [
	{title:"2010 – THE BIG BANG", videoID: "https://www.youtube.com/embed/T-pe1QBEuY4", descInfo: "In 2010, BACARDÍ  NH7 Weekender was born at Koregaon Park, Pune. With 35 artists across 4 stages headlined by Asian Dub Foundation, it would spark a fire that continues to rage to this day."},
	{title:"2011 – v2.0", videoID: "https://www.youtube.com/embed/RQxHXowJgWU", descInfo: "The festival came back a year later Vishal Dadlani and Imogen Heap set the stage on fire; Swarathma, Indian Ocean, Bassment Jaxx and many more put on a stellar show; the Dub Station made its debut and over 30 musicians got together for a collaborative “Together Set”."},
	{title:"2012 – BIGGER. BOLDER. BETTER.", videoID: "https://www.youtube.com/embed/A-LIRCNi_b4", descInfo: "The happiest music festival expanded to Delhi and Bangalore for the first time. Featuring the Manganiyar Seduction, Seun Kuti and Egypt 80, Buraka Som Sistema, Megadeth, Testament, Bombay Bicycle Club, Fink and The Staves; be it mosh pits or bass drops, we left no genre behind."},
	{title:"2013 – MO METROS. MO MUSIC.", videoID: "https://www.youtube.com/embed/cRznWRRHm84", descInfo: "Kolkata was added to the roster. With MUTEMATH, Textures, Meshuggah, TesseracT, Skindred, Simian Mobile Disco, Noisia, Benga, Dry the River, And So I Watch You From Afar, Michal Menert, the festival featured over 100 artists spanning the 4 corners of India."},
	{title:"2014 – IT'S NOT JUST ABOUT THE MUSIC", videoID: "https://www.youtube.com/embed/69fwnSBRxDw", descInfo: "At the 5th edition of the happiest music festival, we had Amit Trivedi, The Vaccines, Fear Factory, MUTEMATH, Dinosaur Pile-Up, Motopony, Luke Sital-Singh and Fossils stirring it up at India’s happiest music festival. From vibrant flea markets to art installations, the Weekender state of mind kept bobbing away."},
	{title:"2015 – THE HILLS ARE CALLING", videoID: "https://www.youtube.com/embed/t40_7bvzSLw", descInfo: "2015 saw the first time that BACARDÍ   NH7 Weekender set the stage at the rock capital of India: Shillong. Bengaluru and Pune experienced the festival simultaneously too. Featuring AR Rahman, Mogwai, Megadeth, Mark Ronson, Rodrigo y Gabriela, L. Subramaniam, Flying Lotus, The Map Room, SBTRKT, The Wailers and Niladri Kumar, fond memories remain."},
	{title:"2016 – EXPRESS YOURSELF!", videoID: "https://www.youtube.com/embed/ZmcNMEskwB0", descInfo: "We launched BACARDÍ  NH7 Weekender Express, with mini-experiences tailored to suit 5 cities across India. We also brought Steven Wilson, Shankar Mahadevan, José González, Anoushka Shankar, Patrick Watson, The Joy Formidable, Nucleya, Thaikkudam Bridge and DJ Premier to the festival."},
	{title:"2017 – LAUGHING OUT LOUD", videoID: "https://www.youtube.com/embed/wDAI8UNlE4o", descInfo: "What do you get when a comedian and a musician walk into a bar? The 8th edition of BACARDÍ  NH7 Weekender! With the addition of the comedy stage, the sounds of guitar solos from Steve Vai, the ambient pop from Cigarettes After Sex and laughter from the comedy tent became music to our ears."},
	{title:"2018 – CLOUD 9", videoID: "https://www.youtube.com/embed/uCb1UX1p9Fc", descInfo: "2018 was a hoot for the happiest music festival. Poets of the Fall, Switchfoot, Guthrie Govan, Shankar Mahadevan and Tipriti Kharnbangar and the Clansmen came down to Shillong, while Pune got to see Joe Satriani, FKJ, God is an Astronaut, The Contortionist, Shubha Mudgal, Salim Sulaiman, and Nightmares on Wax set the stage in Pune."},
	{title:"2019 – YEAR X MARKS THE SPOT", videoID: "https://www.youtube.com/embed/uLVAprFN3-c", descInfo: "The happiest music festival turns 10 years old. That’s a decade of good music, good fun and good vibes all around. And we’re just getting started, so get your tickets now and join the fun in Pune and Meghalaya this year."},
	{title:"2020 – WEEKENDER COMES HOME", videoID: "", descInfo: "Year 11 of the happiest music festival features are coming home to you! Expect a weekend of the usual weekender joy, (with performances, virtual parties, chatrooms et al), but this time, you needn’t leave home for it."}		
];