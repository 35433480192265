import { isMobile, isAndroid } from 'react-device-detect';
import swal from '@sweetalert/with-react';
import Axios from 'axios';

function validateEmail(email) {
  // eslint-disable-next-line max-len
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const handleSubmission = async () => {
  try {
    let name = null;
    do {
      // eslint-disable-next-line no-await-in-loop
      name = await swal({
        text: 'Name',
        content: {
          element: 'input',
          attributes: {
            placeholder: 'Enter Full Name',
          },
        },
        button: {
          text: 'Next',
          closeModal: false,
        },
      });
      if (!name) {
        // eslint-disable-next-line no-await-in-loop
        await swal({
          title: 'Error',
          text: 'Invalid Name',
          icon: 'error',
        });
      }
    } while (!name);
    let email = null;
    do {
      // eslint-disable-next-line no-await-in-loop
      email = await swal({
        text: 'email',
        content: {
          element: 'input',
          attributes: {
            placeholder: 'example@domain.com',
          },
        },
        button: {
          text: 'Submit',
          closeModal: false,
        },
      });
      if (!email || !validateEmail(email)) {
        // eslint-disable-next-line no-await-in-loop
        await swal({
          title: 'Error',
          text: 'Invalid Email',
          icon: 'error',
        });
      }
    } while (!email || !validateEmail(email));
    swal({
      title: 'Submitting',
      text: `please wait...`,
      icon: 'info',
      button: {
        visible: false,
      },
      closeOnClickOutside: false,
      closeOnEsc: false,
    });

    await Axios.post('https://submissions.sportvot.com/bacardi-sheets', {
      email,
      name,
      point: localStorage.getItem('bar_game_total_point') || '0',
    });

    swal({
      title: 'Success',
      // eslint-disable-next-line max-len
      text: `We will contact you on ${email} if you are one of our lucky winners to win the BACARDI - festival kit`,
      icon: 'success',
    });

    localStorage.setItem('bar_game_total_point', '0');
  } catch (e) {
    swal({
      title: 'Error',
      text: 'Oops! Something went wrong.',
      icon: 'error',
    });
    swal.stopLoading();
    swal.close();
  }
};

const beginSubmissionFlow = (fullScreenHandle) => {
  if (isMobile && isAndroid) {
    fullScreenHandle.exit();
  }
  handleSubmission();
};

export default beginSubmissionFlow;
