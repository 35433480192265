import React from 'react';
import styles from './styles.module.css';

function LeftControls({ onRecipeClicked }) {
  return (
    <div className={styles.container}>
      <div onClick={onRecipeClicked} className={styles['action-button']}>
        <img
          className={styles['recipes-button-image']}
          alt="left-recipe-btn"
          src="/sv-gaming/bacardi/assets/images-bacardi/btn-container.png"
        />
        <p className={styles['btn-text']}>RECIPES</p>
      </div>
    </div>
  );
}

export default LeftControls;
