import React, { useState } from 'react';
import styles from './styles.module.css';

function isValidAge(checkDateObj, requiredAge) {
  const today = new Date();
  const nowYear = today.getFullYear();
  const nowMonth = today.getMonth();
  const nowDay = today.getDate();

  const birthYear = checkDateObj.getFullYear();
  const birthMonth = checkDateObj.getMonth();
  const birthday = checkDateObj.getDate();

  let age = nowYear - birthYear;
  const ageMonth = nowMonth - birthMonth;
  const ageDay = nowDay - birthday;

  if (age > 100) {
    return false;
  }

  if (ageMonth < 0 || (ageMonth === 0 && ageDay < 0)) {
    age = parseInt(age, 10) - 1;
  }

  if (
    (age === requiredAge && ageMonth <= 0 && ageDay <= 0) ||
    age < requiredAge
  ) {
    return false;
  }

  return true;
}

function LegalAgeChecker({ onLegalAge, onIllegalAge }) {
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');

  const onEnter = () => {
    try {
      if (!day || !month || !year || year.length !== 4) {
        throw new Error();
      }

      const dayNum = +day;
      const monthNum = +month;
      const yearNum = +year;

      if (day <= 0 || day > 31) {
        throw new Error();
      }

      if (month < 1 || month > 12) {
        throw new Error();
      }

      if (year <= 0) {
        throw new Error();
      }

      const dob = new Date(yearNum, monthNum - 1, dayNum);

      if (!dob) {
        throw new Error();
      }

      if (isValidAge(dob, 25)) {
        onLegalAge();
      } else {
        // eslint-disable-next-line no-alert
        alert(
          'Sorry, you must be 25 years or older to be able to play this game!',
        );
        onIllegalAge();
      }
    } catch (error) {
      console.log(error);
      // eslint-disable-next-line no-alert
      alert('please enter a valid date in DD / MM / YYYY format');
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles['middle-container']}>
        <div className={styles['middle-container__text']}>
          YOU MUST BE OF LEGAL DRINKING AGE TO ENTER THIS SITE
        </div>
        <input
          value={day}
          onChange={(e) => setDay(e.target.value)}
          maxLength="2"
          inputMode="numeric"
          placeholder="DD"
          className={styles['middle-container__date-input']}
        />
        <input
          value={month}
          onChange={(e) => setMonth(e.target.value)}
          maxLength="2"
          placeholder="MM"
          inputMode="numeric"
          className={styles['middle-container__date-input']}
        />
        <input
          value={year}
          onChange={(e) => setYear(e.target.value)}
          maxLength="4"
          placeholder="YYYY"
          inputMode="numeric"
          className={styles['middle-container__date-input']}
        />
        <button
          onClick={onEnter}
          inputMode="numeric"
          type="button"
          className={styles['middle-container__submit']}
        >
          ENTER
        </button>
      </div>
    </div>
  );
}

export default LegalAgeChecker;
