import React from 'react';
import styles from './styles.module.css';

function BackConfirmation({ onYesSelected, onNoSelected }) {
  return (
    <div className={styles.container}>
      <div className={styles['middle-container']}>
        <div className={styles['middle-container__feedback']}>
          Are You Sure?
        </div>

        <div className={styles['actions-container']}>
          <div
            onClick={onYesSelected}
            className={styles['action-container__button']}
          >
            Yes
          </div>
          <div
            onClick={onNoSelected}
            className={styles['action-container__button']}
          >
            No
          </div>
        </div>
      </div>
    </div>
  );
}

export default BackConfirmation;
