/* eslint-disable no-nested-ternary */
import React from 'react';
import styles from './styles.module.css';

function StartScreenButton({
  onClick = () => console.log('button clicked'),
  isLower,
  text = 'START',
  isLeft,
}) {
  return (
    <div
      onClick={onClick}
      className={`${styles.container} ${
        isLeft
          ? styles['container--left']
          : isLower
          ? styles['container--lower']
          : styles['container--upper']
      }`}
    >
      <div className={styles['text-container']}>
        <div className={styles['text-box-wrapper']}>
          <div className={styles['text-box']}>
            <img
              style={{ width: '100%', height: '100%' }}
              src="/sv-gaming/bacardi/assets/images-bacardi/btn-container.png"
              alt="img"
            />
            <p className={styles['btn-text']}>{text}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StartScreenButton;
