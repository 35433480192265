import React from 'react';
import { dw12 } from '../../constants/ShelfConfig';
import styles from './styles.module.css';

function DW12({ enabled, onClick }) {
  return (
    <img
      onClick={() => enabled && onClick(dw12)}
      src={dw12.src}
      alt="extra-bottle"
      className={`${styles['extra-bottle']} ${
        enabled ? '' : styles['extra-bottle--faded']
      }`}
    />
  );
}

export default DW12;
