import React, { useEffect } from 'react';
import styles from './styles.module.css';

// re-render to start animation again
function Stirrer({ onStirComplete = () => console.log('stir complete') }) {
  useEffect(() => {
    setTimeout(() => {
      onStirComplete();
    }, 3000);
  }, []);

  return <div className={styles.stirrer} />;
}

export default Stirrer;
