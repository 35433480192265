import React from 'react';
import styles from './styles.module.css';

function FirstScreenBranding() {
  // eslint-disable-next-line react/jsx-curly-brace-presence
  return (
    <div className={styles.container}>
      <div> Dewar&apos;s</div>
      <div>HighBalls</div>
    </div>
  );
}

export default FirstScreenBranding;
