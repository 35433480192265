import React from 'react';
import styles from './styles.module.css';

function RightControls({
  onDropOrPourClicked,
  dropOrPourText = 'Pour',
  onStirClicked,
  onResetClicked,
}) {
  return (
    <div className={styles.container}>
      <div onClick={onDropOrPourClicked} className={styles['action-button']}>
        {dropOrPourText}
      </div>
      <div onClick={onStirClicked} className={styles['action-button']}>
        Stir
      </div>
      <div onClick={onResetClicked} className={styles['action-button']}>
        Reset
      </div>
    </div>
  );
}

export default RightControls;
