const sounds = ['/sv-gaming/colorfy/sounds/bg.wav'];

const SoundLoader = async () => {
  try {
    const promises = await sounds.map((src) => {
      return new Promise((resolve, reject) => {
        // eslint-disable-next-line no-undef
        const audio = new Audio();
        audio.src = src;
        audio.onload = resolve;
        audio.onerror = reject;
      });
    });
    await Promise.all(promises);
    return true;
  } catch (error) {
    // eslint-disable-next-line no-alert
    console.log('failed to load sound assets', error);
  }
};

export default SoundLoader;
