import React, {useState} from 'react';
import './assets/styles/BuyTickets.css';
// import './assets/styles/Lineup.css';
import './assets/styles/Lineup.css'
import LineUpDesktop from './assets/images/line-up.png'
import LineUpMobile from './assets/images/line-up-2.png';
import artist1 from './assets/artist/1.png'
import artist2 from './assets/artist/2.png'
import artist3 from './assets/artist/3.png'
import artist4 from './assets/artist/4.png'
import artist5 from './assets/artist/5.png'
import artist6 from './assets/artist/6.png'
import artist7 from './assets/artist/7.png'
import artist8 from './assets/artist/8.png'
import artist9 from './assets/artist/9.png'
import artist10 from './assets/artist/10.png'
import artist11 from './assets/artist/11.png'
import artist12 from './assets/artist/12.png'
import artist13 from './assets/artist/13.png'
import artist14 from './assets/artist/14.png'
import artist15 from './assets/artist/15.png'
import artist16 from './assets/artist/16.png'
import artist17 from './assets/artist/17.png'
import artist18 from './assets/artist/18.png'
import artist19 from './assets/artist/19.png'
import artist20 from './assets/artist/20.png'
import Aos from "aos";
import "aos/dist/aos.css";
import artist21 from './assets/artist/21.png'
import artist22 from './assets/artist/22.png'
import artist23 from './assets/artist/23.png'
import artist24 from './assets/artist/24.png'
import artist25 from './assets/artist/25.png'
import artist26 from './assets/artist/26.png'


// import Aos from "aos";
import "aos/dist/aos.css";
import {Container,Row ,Col,Image} from 'react-bootstrap';



export default props => {
    const [createGrid, setCreateGrid] = useState(false);
    React.useEffect(() => {
        Aos.init({duration:2000});
    }, [])

    React.useEffect(()=>{
        // let root = document.getElementById('root');
		document.body.style.backgroundColor="#29cc96";
		let f = document.getElementById("footer");
		f.style.backgroundColor="#29cc96";


		props.addLeaves();
        document.getElementById("line-up").className="lineup-active"
        document.getElementById("lineup-link-mob").className="lineup-active";
        
        // document.getElementById("lineup-link").style.color="white";
        Aos.init({duration:2000});
        
        return (()=>{
			document.getElementById("line-up").className=""
            document.getElementById("lineup-link").style.color="black";
            document.getElementById("lineup-link-mob").className="";
            
			props.removeLeaves();
        })
        
    },[])
    React.useEffect(()=>{
        props.createGridFunc(createGrid, "lineUpContent", "firstItem", 1, 0, 0, 0)
        props.createGridFunc(createGrid, "LinupContent2", "firstLineUpItem", 1, 0, 3, 0)

	},[createGrid])
    return (
        <div className="FAQPage" >
            {/* HELLO */}
            <Container fluid >
			    <Row className="mt-2 ">
                    <Col xl={1}>
                    {/* 29cc96 */}
                    </Col>
                    <Col>
                         <span className="line-up-title">#LINEUP2020</span>
                    </Col>
                  
			     </Row>
                 <Row id="lineUpContent" onLoad={()=>{setCreateGrid(true)}}>
					 <Col id="firstItem"></Col>
                     
                     <Col xl={12} className="line-up-desktop-img align-img">
                          <Image fluid src={LineUpDesktop} />
                     </Col>
                     <Col xl={12}  className="line-up-mobile-img align-img">
                          <Image fluid src={LineUpMobile} />
                     </Col>
                   
                 </Row>
                 <Row  id="LinupContent2">
                    <Row  id="firstLineUpItem" className="pr-5 pl-5 mt-5 align-img">
                        <Col data-aos-once ="false"  data-aos="fade-up" xl={4} className="mt-2" >
                            <Image fluid src={artist24} />
                        </Col>
                        <Col data-aos-once ="false"  data-aos="fade-up" xl={4} className="mt-2">
                            <Image fluid src={artist1} />
                        </Col>
                        <Col data-aos-once ="false"  data-aos="fade-up" xl={4} className="mt-2">
                            <Image fluid src={artist2} />   
                        </Col>
                    </Row>
                    <Row  className="pr-5 pl-5 mt-3 align-img">
                        <Col data-aos-once ="false"  data-aos="fade-up" xl={4} className="mt-2">
                            <Image fluid src={artist25} />
                        </Col>
                        <Col data-aos-once ="false"  data-aos="fade-up" xl={4} className="mt-2">
                            <Image fluid src={artist26} />
                        </Col>
                        <Col data-aos-once ="false"  data-aos="fade-up" xl={4} className="mt-2">
                            <Image fluid src={artist3} />   
                        </Col>
                    </Row>
                    <Row className="pr-5 pl-5 mt-3 align-img">
                        <Col  data-aos-once ="false"  data-aos="fade-up"  xl={4} className="mt-2">
                            <Image fluid src={artist4} />
                        </Col>
                        <Col  data-aos-once ="false"  data-aos="fade-up"  xl={4} className="mt-2">
                            <Image fluid src={artist5} />
                        </Col>
                        <Col  data-aos-once ="false"  data-aos="fade-up"  xl={4} className="mt-2">
                            <Image fluid src={artist6} />   
                        </Col>
                    </Row>
                    <Row  className="pr-5 pl-5 mt-3 align-img">
                        <Col data-aos-once ="false"  data-aos="fade-up" xl={4} className="mt-2">
                            <Image fluid src={artist7} />
                        </Col>
                        <Col data-aos-once ="false"  data-aos="fade-up" xl={4} className="mt-2">
                            <Image fluid src={artist8} />
                        </Col>
                        <Col data-aos-once ="false"  data-aos="fade-up" xl={4} className="mt-2">
                            <Image fluid src={artist9} />   
                        </Col>
                    </Row>
                    <Row  className="pr-5 pl-5 mt-3 align-img">
                        <Col data-aos-once ="false"  data-aos="fade-up" xl={4} className="mt-2">
                            <Image fluid src={artist10} />
                        </Col>
                        <Col data-aos-once ="false"  data-aos="fade-up" xl={4} className="mt-2">
                            <Image fluid src={artist11} />
                        </Col>
                        <Col data-aos-once ="false"  data-aos="fade-up" xl={4} className="mt-2">
                            <Image fluid src={artist12} />   
                        </Col>
                    </Row>
                    <Row  className="pr-5 pl-5 mt-3 align-img">
                        <Col data-aos-once ="false"  data-aos="fade-up" xl={4} className="mt-2">
                            <Image fluid src={artist13} />
                        </Col>
                        <Col data-aos-once ="false"  data-aos="fade-up" xl={4} className="mt-2">
                            <Image fluid src={artist14} />
                        </Col>
                        <Col data-aos-once ="false"  data-aos="fade-up"  xl={4} className="mt-2">
                            <Image fluid src={artist15} />   
                        </Col>
                    </Row>
                    <Row className="pr-5 pl-5 mt-3 align-img">
                        <Col data-aos-once ="false"  data-aos="fade-up"  xl={4} className="mt-2">
                            <Image fluid src={artist16} />
                        </Col>
                        <Col data-aos-once ="false"  data-aos="fade-up"  xl={4} className="mt-2">
                            <Image fluid src={artist17} />
                        </Col>
                        <Col data-aos-once ="false"  data-aos="fade-up"  xl={4} className="mt-2">
                            <Image fluid src={artist18} />   
                        </Col>
                    </Row>
                    <Row  className="pr-5 pl-5 mt-3 align-img">
                        <Col data-aos-once ="false"  data-aos="fade-up" xl={4} className="mt-2">
                            <Image fluid src={artist19} />
                        </Col>
                        <Col data-aos-once ="false"  data-aos="fade-up" xl={4} className="mt-2">
                            <Image fluid src={artist20} />
                        </Col>
                        <Col data-aos-once ="false"  data-aos="fade-up" xl={4} className="mt-2">
                            <Image fluid src={artist21} />
                        </Col>
                        <Col data-aos-once ="false"  data-aos="fade-up" xl={4} className="mt-2">
                            <Image fluid src={artist22} />
                        </Col>
                        <Col data-aos-once ="false"  data-aos="fade-up" xl={4} className="mt-2">
                            <Image fluid src={artist23} />
                        </Col>
                    </Row>
                   
                 </Row>
                
             </Container>
            
            
            
        </div>
    )
}
