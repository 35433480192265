import React from 'react';
import { REDIRECT_URL } from '../../constants';
import StartScreenButton from '../StartScreenButton/StartScreenButton';

function FirstScreenControls({ onStartClicked, onRecipesClicked }) {
  return (
    <>
      <StartScreenButton
        onClick={() => {
          //window.location.href = REDIRECT_URL;
           window.close()
        }}
        isLower={false}
        text="GO TO MAIN SITE"
        isLeft
      />
      <StartScreenButton
        onClick={onStartClicked}
        isLower={false}
        text="START GAME"
      />
      <StartScreenButton onClick={onRecipesClicked} isLower text="RECIPES" />
    </>
  );
}

export default FirstScreenControls;
