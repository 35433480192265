import Faq1Img from './assets/images/1.png'
import Faq2Img from './assets/images/2.png'
import Faq3Img from './assets/images/3.png'
import Faq4Img from './assets/images/4.png'
import Faq5Img from './assets/images/5.png'
import Faq6Img from './assets/images/6.png'
import Faq7Img from './assets/images/7.png'
import Faq8Img from './assets/images/8.png'
import Faq9Img from './assets/images/9.png'
import Faq10Img from './assets/images/10.png'
import Faq11Img from './assets/images/11.png'
import Faq12Img from './assets/images/12.png'
import Faq13Img from './assets/images/13.png'
import Faq14Img from './assets/images/14.png'
import Faq15Img from './assets/images/15.png'
import Faq16Img from './assets/images/16.png'
import Faq17Img from './assets/images/17.png'
import Faq18Img from './assets/images/18.png'
import Faq19Img from './assets/images/19.png'
import Faq20Img from './assets/images/20.png'
import Faq21Img from './assets/images/21.png'
import Faq22Img from './assets/images/22.png'
import Faq23Img from './assets/images/23.png'
import Faq24Img from './assets/images/24.png'
import Faq25Img from './assets/images/25.png'
import Faq26Img from './assets/images/26.png'

export default [
    {key:1, src:Faq1Img, isFirst:true},
    {key:2, src:Faq2Img},
    {key:3, src:Faq3Img},
    {key:4, src:Faq4Img},
    {key:5, src:Faq5Img},
    {key:6, src:Faq6Img},
    {key:7, src:Faq7Img},
    {key:8, src:Faq8Img},
    {key:9, src:Faq9Img},
    {key:10, src:Faq10Img},
    {key:11, src:Faq11Img},
    {key:12, src:Faq12Img},
    {key:13, src:Faq13Img},
    {key:14, src:Faq14Img},
    {key:15, src:Faq15Img},
    {key:16, src:Faq16Img},
    {key:17, src:Faq17Img},
    {key:18, src:Faq18Img},
    {key:19, src:Faq19Img},
    {key:20, src:Faq20Img},
    {key:21, src:Faq21Img},
    {key:22, src:Faq22Img},
    {key:23, src:Faq23Img},
    {key:24, src:Faq24Img},
    {key:25, src:Faq25Img},
    {key:26, src:Faq26Img, isLast : true}

];