import { dw12, coconutConfig, soda, ale } from '../../constants/ShelfConfig';

export const RECIPE_CONFIG = {
  COCONUT_HIGHBALL: {
    id: 0,
    steps: 5,
    title: 'COCONUT HIGHBALL',
  },
  ORIGINAL_HIGHBALL: {
    id: 1,
    steps: 5,
    title: 'ORIGINAL HIGHBALL',
  },
  TOMMY_HIGHBALL: {
    id: 2,
    steps: 5,
    title: "TOMMY'S HIGHBALL",
  },
};

export const getRandomRecipeKey = () => {
  const configKeys = Object.keys(RECIPE_CONFIG);
  const randomIndex = Math.floor(Math.random() * configKeys.length);
  return configKeys[randomIndex];
};

// ------------------- current status evaluators ----------------------

const getCurrentStatusCoconutHighBall = (currentSteps) => {
  const retVal = [];

  if (currentSteps[0]) {
    retVal.push(currentSteps[0] === 'dw12');
  }

  if (currentSteps[1]) {
    retVal.push(currentSteps[1] === 'coconut');
  }

  if (currentSteps[2]) {
    retVal.push(currentSteps[2] === 'stir');
  }

  if (currentSteps[3]) {
    retVal.push(currentSteps[3] === 'lemon');
  }

  if (currentSteps[4]) {
    retVal.push(currentSteps[4] === 'lemonWithLeaf');
  }

  return retVal;
};

const getCurrentStatusOriginalHighBall = (currentSteps) => {
  const retVal = [];

  if (currentSteps[0]) {
    retVal.push(currentSteps[0] === 'dw12');
  }
  if (currentSteps[1]) {
    retVal.push(currentSteps[1] === 'soda');
  }
  if (currentSteps[2]) {
    retVal.push(currentSteps[2] === 'stir');
  }
  if (currentSteps[3]) {
    retVal.push(currentSteps[3] === 'lemon');
  }
  if (currentSteps[4]) {
    retVal.push(currentSteps[4] === 'lemonTwist');
  }

  return retVal;
};

const getCurrentStatusTommyHighBall = (currentSteps) => {
  const retVal = [];

  if (currentSteps[0]) {
    retVal.push(currentSteps[0] === 'dw12');
  }
  if (currentSteps[1]) {
    retVal.push(currentSteps[1] === 'ale');
  }
  if (currentSteps[2]) {
    retVal.push(currentSteps[2] === 'stir');
  }
  if (currentSteps[3]) {
    retVal.push(currentSteps[3] === 'lemon');
  }
  if (currentSteps[4]) {
    retVal.push(currentSteps[4] === 'leaf');
  }

  return retVal;
};

// returns array of [true, false, true]
// size of returned array is min(currentSteps.length, recipe number of steps)
export const getCurrentStatus = (recipeId, currentSteps) => {
  switch (recipeId) {
    case RECIPE_CONFIG.COCONUT_HIGHBALL.id:
      return getCurrentStatusCoconutHighBall(currentSteps);
    case RECIPE_CONFIG.ORIGINAL_HIGHBALL.id:
      return getCurrentStatusOriginalHighBall(currentSteps);
    case RECIPE_CONFIG.TOMMY_HIGHBALL.id:
      return getCurrentStatusTommyHighBall(currentSteps);
    default:
      return false;
  }
};

// ------------ get current glass contents color --------

function hexToRgb(hex) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const newHex = hex.replace(shorthandRegex, (m, r, g, b) => {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(newHex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

const constructAvgColor = (hexColors = []) => {
  if (!hexColors || hexColors.length === 0) return `rgb(255, 255, 255)`;

  const rgbObjects = [];

  for (let index = 0; index < hexColors.length; index += 1) {
    rgbObjects.push(hexToRgb(hexColors[index]));
  }

  let redSum = 0;
  let greenSum = 0;
  let blueSum = 0;

  for (let index = 0; index < rgbObjects.length; index += 1) {
    redSum += rgbObjects[index].r;
    greenSum += rgbObjects[index].g;
    blueSum += rgbObjects[index].b;
  }

  const len = rgbObjects.length;
  const redAvg = Math.floor(redSum / len);
  const greenAvg = Math.floor(greenSum / len);
  const blueAvg = Math.floor(blueSum / len);

  return `rgb(${redAvg}, ${greenAvg}, ${blueAvg})`;
};

export const getCurrentGlassColor = (currentSteps) => {
  const colors = [];
  for (let index = 0; index < currentSteps.length; index += 1) {
    if (currentSteps[index] === 'dw12') {
      colors.push(dw12.color);
    }
    if (currentSteps[index] === 'coconut') {
      colors.push(coconutConfig.color);
    }
    if (currentSteps[index] === 'soda') {
      colors.push(soda.color);
    }

    if (currentSteps[index] === 'ale') {
      colors.push(ale.color);
    }
  }

  return constructAvgColor(colors);
};

export const getCurrentGlassLevel = (currentSteps) => {
  let level = 0;
  for (let index = 0; index < currentSteps.length; index += 1) {
    if (currentSteps[index] === 'dw12') {
      level += 1;
    }
    if (currentSteps[index] === 'coconut') {
      level += 1;
    }
    if (currentSteps[index] === 'soda') {
      level += 1;
    }

    if (currentSteps[index] === 'ale') {
      level += 1;
    }

    if (currentSteps[index] === 'lemon') {
      level += 1;
    }
  }

  return Math.min(3, level);
};
