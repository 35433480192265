import React, {useState} from 'react';
import './assets/styles/LoadingPage.css';
import "aos/dist/aos.css";
import {Container,Row ,Col,Image,Spinner} from 'react-bootstrap';

export default props => {
    return (
        <Container fluid>
            <Row className="loadingDiv">
                <Col>
                    <h1 className="loadingText">BACARDI</h1>
                    <h1 className="loadingText">NH7 WEEKENDER</h1>
                    <h6 className="loadingSubText">THE HAPPIEST MUSIC FESTIVAL<br/>COMES HOME TO YOU!</h6>
                    {/* <h6 className="loadingSubText">COMES HOME TO YOU!</h6> */}
                    <Spinner className="mt-4"  animation="border"  size="md" variant="primary" />
                </Col>
            </Row>
            {/* <div className="loadingDiv" >
              
            </div> */}
        </Container>
    )
}