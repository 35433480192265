import React, {useState } from 'react';
import FAQImg from './assets/images/FAQQ.png'
import Strip from './assets/images/Strip.png';
import './assets/styles/FAQ.css';
import FAQData from './FAQData.js';


import Aos from "aos";
import "aos/dist/aos.css";

const FAQCard = props => {
    return props.data.map( item =>
        (
            item.isFirst?<div key={item.key} data-aos="fade-up" className="FAQBlck">
                <img src={item.src} className="FAQImg" />
            </div>
            :
                item.isLast?
                <div key={item.key} onLoad={()=>{props.setCreateGrid(true)}} data-aos="fade-up" className="FAQBlck">
                    <img src={item.src} className="FAQImg" />
                </div>
                :
                <div key={item.key} data-aos="fade-up" className="FAQBlck" id="firstItem">
                    <img src={item.src} id="fireImg" className="FAQImg" />
                </div>
        )
    )
};

export default props => {
    const [createGrid, setCreateGrid] = useState(false);

    React.useEffect(() => {
        Aos.init({duration: 2000});
    }, [])

    React.useEffect(()=>{
        // let root = document.getElementById('root');
		document.body.style.backgroundColor="#a668ba";
		let f = document.getElementById("footer");
		f.style.backgroundColor="#a668ba";


		props.addLeaves();
        document.getElementById("faqs").className="faq-active";
        document.getElementById("faqs-mob").className="faq-active";

        // alert(document.getElementById("faqs").className);/
        document.getElementById("faqs-link").style.color="white";
		document.getElementById("faqs-link-mob").style.color="white";
        
        return (()=>{
            document.getElementById("faqs").className=""
			document.getElementById("faqs-mob").className=""
            
            document.getElementById("faqs-link").style.color="black";
			document.getElementById("faqs-link-mob").style.color="black";
            
			props.removeLeaves();
		})
    },[])
    React.useEffect(()=>{
        props.createGridFunc(createGrid, "FAQContent", "firstItem", 1, 2, 2, 0);
	},[createGrid])
    return (
        <div className="FAQPage">
            {/* HELLO */}
            <div className="FAQHead">
                <div className="FaqHeadTitleBlck">
                    <div className="TitleBlck">
                        <h1 className="FaqTitle">FAQs</h1>
                    </div>
                    <div className="TitleImgBlck">
                        <img className="FaqTitleImg" src={Strip} />
                    </div>
                </div>
                <div className="FaqHeadImgBlck">
                    <img className="SideImgfaq" src={FAQImg} />
                </div>
            </div>
          
            <div className="FAQContent" id="FAQContent">
                <FAQCard data={FAQData} setCreateGrid={setCreateGrid} />
            </div>
            
        </div>
    )
}