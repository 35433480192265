import React from 'react';
import './assets/fonts/Gosha Sans/TTF/GoshaSans-Ultrabold.ttf'
import './assets/styles/ContactUs.css'
import ContactUsImg from './assets/images/contact_us.png';

export default props => {
    const [createGrid, setCreateGrid] = React.useState(false);
	const [renderForPhone, setRenderForPhone] = React.useState(false);
	React.useEffect(()=>{
		let f = document.getElementById("footer");
		f.style.backgroundColor="#ffdd46";
		document.body.style.backgroundColor = '#ffdd46';


		props.addLeaves();
		document.getElementById("contact").className="contact-us-active";
		document.getElementById("contact-link").style.color="white";
		document.getElementById("contact-mob").className="contact-us-active";
		document.getElementById("contact-link-mob").style.color="white";
		if(window.innerWidth<769)
		{
			setRenderForPhone(true);
		}
        // document.body.insertBefore(leavesDiv, root);
        return (()=>{
			document.getElementById("contact").className="";
		document.getElementById("contact-link").style.color="black";
		document.getElementById("contact-mob").className="";
		document.getElementById("contact-link-mob").style.color="black";
			// document.body.removeChild(leavesDiv)
			props.removeLeaves();
		})
	},[])
	
	React.useEffect(()=>{
		if(window.innerWidth>769)
		props.createGridFunc(createGrid, "ContactUs-content", "firstItem", 1, 0, 0, 0);
		else
		props.createGridFunc(createGrid, "ContactUs-content", "firstItem", 1, 0, 0, 1);

	},[createGrid])

	return (
		<div className="ContactUs-Content">
			<div id="content-cu" className="ContactUs-Elems-text">
				<div className="ContactUs-Title-Blck">
					<h1 className="ContactUs-Title">CONTACT US</h1>
				</div>

				{/* <div onLoad={()=>{setCreateGrid(true)}} id="ContactUs-content" className="ContactUs-ElemsImg">
					<img id="firstItem" src={ContactUsImg} className="bat"/>
				</div> */}
				{!renderForPhone?<div onLoad={()=>{setCreateGrid(true)}} id="ContactUs-content" className="ContactUs-ElemsImg">
					<img id="firstItem" src={ContactUsImg} className="bat"/>
				</div>:null}
				{
					!renderForPhone?<div className="ContactUs-Parent-Desc">
						<div className="ContactUs-Desc">
							<ul >
							<li>HAVE A QUESTION REGARDING THE FESTIVAL?</li>
							<li>DROP US AN EMAIL AT NH7HELP@OML.IN</li>
							<li>FOR ALL TICKET-RELATED QUERIES: HELP@INSIDER.IN</li>
							<li>HIT US UP ON SOCIAL AND SAY HEY!</li>
							{/* <li>FOR ANY TICKETING QUERIES MAIL HELP@INSIDER.IN</li> */}
							{/* <li>IG logo link | FB logo link | Twitter logo link</li> */}
							</ul>
						</div>
					</div>:
					<div id="ContactUs-content" className="ContactUs-Parent-Desc mobile_content">
						<li id="firstItem"></li>
						<div  className="ContactUs-Desc">

							<ul id="content-cu">

								<li>HAVE A QUESTION REGARDING THE FESTIVAL?</li>
								<li>DROP US AN EMAIL AT NH7HELP@OML.IN</li>
								<li>FOR ALL TICKET-RELATED QUERIES: HELP@INSIDER.IN</li>
								<li>HIT US UP ON SOCIAL AND SAY HEY!</li>
							</ul>
						</div>
					</div>
				}
				

				{renderForPhone?<div onLoad={()=>{setCreateGrid(true)}} id="ContactUs-content" className="ContactUs-ElemsImg mobile_content">
					<img id="firstItem" src={ContactUsImg} className="bat"/>
				</div>:null}
			</div>
			
		</div>
	);
};
