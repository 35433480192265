import React from 'react';
import styles from './styles.module.css';

function CancelSelectionButton({ enabled = false, onClick }) {
  return (
    enabled && (
      <div onClick={onClick} className={styles['cancel-button']}>
        cancel selection
      </div>
    )
  );
}

export default CancelSelectionButton;
