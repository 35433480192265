const imgs = [
  '/sv-gaming/colorfy/images/bg-main.png',
  '/sv-gaming/colorfy/images/bg-start.png',
  '/sv-gaming/colorfy/images/top-logo.png',

  '/sv-gaming/colorfy/images/colors/blue-color.png',
  '/sv-gaming/colorfy/images/colors/orange-color.png',
  '/sv-gaming/colorfy/images/colors/pink-color.png',
  '/sv-gaming/colorfy/images/colors/purple-color.png',
  '/sv-gaming/colorfy/images/colors/yellow-color.png',

  '/sv-gaming/colorfy/svgs/1.svg',
  '/sv-gaming/colorfy/svgs/2.svg',
  '/sv-gaming/colorfy/svgs/3.svg',
  '/sv-gaming/colorfy/svgs/4.svg',
  '/sv-gaming/colorfy/svgs/5.svg',
  '/sv-gaming/colorfy/svgs/6.svg',
];

const ImageLoader = async () => {
  try {
    const promises = await imgs.map((src) => {
      return new Promise((resolve, reject) => {
        // eslint-disable-next-line no-undef
        const img = new Image();
        img.src = src;

        img.onload = resolve;
        img.onerror = reject;
      });
    });
    await Promise.all(promises);
    return true;
  } catch (error) {
    // eslint-disable-next-line no-alert
    console.log('failed to load image assets', error);
  }
};

export default ImageLoader;
