import React, { useState } from 'react';
import Footer from './FooterNew.js';
// import Footer from './Footer.js';

import Navbar from './Navbar.js';
import ContactUs from './ContactUs.js';
import VideoPage from './VideoPage.js';
import BuyTickets from './BuyTickets.js';
// import PhotoBooth from './PhotoBooth.js';
import Colorfy from "./sv_gaming/colorfy/src/App";
import Dewars from "./sv_gaming/dewars/src/App";
import Bacardi from "./sv_gaming/bacardi/src/App";

import Drag from './Drag.js';
import HeaderNew from './NavBarNew.js';
import LineUp from './LineUp.js';
import FAQ from './FAQ.js'
import Home from './Home.js'
import OrientationError from './OrientationError.js';
import L1 from './assets/images/header_leaf.png';
import Leaf2 from './assets/images/header_leaf_2.png';
import L1_r from './assets/images/header_leaf_right.png';
import Leaf2_r from './assets/images/header_leaf_2_right2.png';
import TicketScript from './ticketScript.js';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link
  } from "react-router-dom";
import PhotoBooth from './PhotoBooth.js';

function addLeaves()
{
	let root = document.getElementById('root');
		let leavesDiv = document.createElement('div');
		leavesDiv.id="leavesDiv"
		leavesDiv.style.position = "absolute";
		let leavesDim = 100;
		for(let i=0; i<parseInt(window.innerWidth/leavesDim); i++)
		{
			let imgLi = document.createElement('li');
			imgLi.style.display="inline-block";
			imgLi.className="leafLi"
			if(i%2==1)
			{
				//console.log(`odd`)

				let imgtype1 = document.createElement('img');
				if(i%3)
				imgtype1.src=L1;
				else
				imgtype1.src=L1_r;
				imgtype1.style.width=`${leavesDim}px`;
				imgtype1.style.height=`${leavesDim}px`;
				imgtype1.style.objectFit="contain";
				// imgtype1.style.top="-25px";
				//console.log(imgtype1)
				imgLi.appendChild(imgtype1)

			}
			else{
				//console.log(`even`)
				let imgtype2 = document.createElement('img');	
				if(i%4)	
				imgtype2.src=Leaf2;
				else
				imgtype2.src=Leaf2_r;
				imgtype2.style.width=`${leavesDim}px`;
				imgtype2.style.height=`${leavesDim}px`;
				imgtype2.style.objectFit="contain";
				imgLi.appendChild(imgtype2)
				
			}
			leavesDiv.appendChild(imgLi);
			leavesDiv.style.top="-50px"
		}
        document.body.insertBefore(leavesDiv, root);
}
function removeLeaves()
{
	let leavesDiv = document.getElementById('leavesDiv');
	// leavesDiv.id="leavesDiv"

	document.getElementById("contact").className="";
		document.getElementById("contact-link").style.color="black";
			document.body.removeChild(leavesDiv);
}
function createGridFunc(createGrid, gridOnElem, firstChild, alignRight, offsetAmt, offsetAmtTop, addRows)
{
	if(createGrid){let grid = document.createElement('table');
		let tableContainer = document.createElement('div');
		tableContainer.id=`${gridOnElem}_tableContainer`;
		tableContainer.style.position="absolute";
		tableContainer.style.textAlign="right";
		tableContainer.style.zIndex="-1";
		let root = document.getElementById('root');
		let tbody = document.createElement('tbody');
		
		let CuE  = document.getElementById(gridOnElem);
		// if(renderForPhone) CuE = document.getElementById("content-cu");

		let fi = document.getElementById(firstChild);
		// if(renderForPhone) fi = document.getElementById("first-contentForMob");

		let bodyHeight = document.body.scrollHeight;
		// let fireImg = document.getElementById("fireImg");
        // for(let x = 0; x<parseInt(CuE.scrollHeight/50-((fireImg.clientHeight/50)*0.5)); x++)
		for(let x = 0; x<parseInt(CuE.clientHeight/50)-offsetAmtTop+addRows; x++)
        
		{
			// console.log(`number of rows : ${parseInt(fi.scrollHeight/50)}`)
			let tr = document.createElement('tr');
			for(let y = 0; y<CuE.clientWidth/50-offsetAmt; y++)
			{
				let td = document.createElement('td');
				td.style.border = "1px solid black";
				td.setAttribute('rowSpan', '1')
				td.setAttribute('colSpan', '1');
				td.setAttribute('height', '50px');
				td.setAttribute('width', '50px');

				tr.appendChild(td);
			}
			tbody.appendChild(tr);
		}
		grid.appendChild(tbody);
		tableContainer.style.marginLeft=50*offsetAmt+"px"
		tableContainer.style.marginTop=50*offsetAmtTop+"px"
		// tableContainer.style.marginLeft=50*(parseInt((window.innerWidth/50)*0.08))+"px"
		// tableContainer.style.marginTop=fireImg.clientHeight*0.3+"px";
		tableContainer.appendChild(grid);
		CuE.insertBefore(tableContainer, fi);
		//console.log(grid);
		
		// if(alignRight)
		grid.style.alignSelf="right";
		tableContainer.style.opacity="0.3";

		}
}
export default props => {
	const [currentActive, setCurrentActive] = useState(0);
	// const [bodyColor, setBodyColor] = useState('white');
    const [orientationErr, setOrientationErr] = useState(false);
	const [showOtherPage, setShowOtherPage] = useState(false);
	const [videoPlayed, setVideoPlayed] = useState(false);
	// const [hideNavbar, setHideNavbar] = useState(true);
	// const [hideFooter, setHideFooter] = useState(true);
	React.useEffect(()=>{
		if (window.navigator.userAgent.match(/Android|iphone/i)&&window.navigator.userAgent.match(/Android|iphone/i).length) 
        {
            if(window.matchMedia("(orientation: landscape)").matches)
                setOrientationErr(true)
        }
		window.addEventListener('orientationchange', ()=>{
        
            if (window.matchMedia("(orientation: portrait)").matches) 
            {
				if (window.navigator.userAgent.match(/Android|iphone/i)&&window.navigator.userAgent.match(/Android|iphone/i).length)
				setOrientationErr(true)
            }
            else   
            {
                setOrientationErr(false);
                window.location.reload()
                // intializeVals();
            }
        });
	},[])
	return (
		<div>
			<React.Fragment>
					{/* <Navbar  currentActive={currentActive} setCurrentActive={setCurrentActive} /> */}
					
					<Switch>
						<Route exact path="/">
							{orientationErr?
								<OrientationError />
								:
								<>
									<HeaderNew />
										<Home videoPlayed={videoPlayed} setVideoPlayed={setVideoPlayed} exact />
									<Footer currentActive={currentActive} />
								</>
							}
						</Route>
						<Route exact path="/photo-booth">
							<PhotoBooth />
						</Route>
						{/* <Route exact path="/music-box">
							<Drag />
						</Route> */}
						<Route component={BuyTickets}  exact path="/over-the-years" >
							<HeaderNew />
								<VideoPage createGridFunc={createGridFunc} addLeaves={addLeaves} removeLeaves={removeLeaves} />
							<Footer currentActive={currentActive} />
						</Route>
						<Route exact path="/game-breezer">
							<Colorfy />
						</Route>

						<Route exact path="/game-bacardi">
							<Bacardi />
						</Route>

						<Route exact path="/game-dewars">
							<Dewars />
						</Route>
						<Route exact path="/faqs">
							<HeaderNew />
								<FAQ createGridFunc={createGridFunc} addLeaves={addLeaves} removeLeaves={removeLeaves} />
							<Footer currentActive={currentActive} />
						</Route>
						<Route exact path="/line-up">
							<HeaderNew />
								<LineUp createGridFunc={createGridFunc} addLeaves={addLeaves} removeLeaves={removeLeaves} />
							<Footer currentActive={currentActive} />
						</Route>
						<Route exact path="/contact">
							<HeaderNew />
								<ContactUs createGridFunc={createGridFunc} addLeaves={addLeaves} removeLeaves={removeLeaves} />
							<Footer currentActive={currentActive} />
						</Route>
						<Route exact path="/buy-tickets">
							<HeaderNew />
								<BuyTickets createGridFunc={createGridFunc} addLeaves={addLeaves} removeLeaves={removeLeaves}  showOtherPage = {showOtherPage} setShowOtherPage={setShowOtherPage} />
							<Footer currentActive={currentActive} />
						</Route>

					</Switch>
					{/* <Footer currentActive={currentActive} /> */}
			</React.Fragment>
		</div>
	)
};
