import React from 'react';

import styles from './styles.module.css';

export default function RecipeScreen({ onBackClicked }) {
  return (
    <div className={styles.container}>
      {/* back button */}
      <div onClick={onBackClicked} className={styles.backBtn}>
        <div className={styles['inner-back-btn']}>
          <p className={styles.backBtnText}>BACK</p>
        </div>
      </div>

      {/* Receipes */}
      <div className={styles.recipeListContainer}>
        <div className={styles.recipeContainer}>
          <img
            alt="recipes"
            src="/sv-gaming/dewars/recipes.png"
            className={styles['recipes-img']}
          />
        </div>
      </div>
    </div>
  );
}
