import React from 'react';
import styles from './styles.module.css';

function Glass() {
  return (
    <img
      src="/sv-gaming/bacardi/assets/images/glass-tall.png"
      alt="glass"
      className={styles.glass}
    />
  );
}

export default Glass;
